import { Link } from 'react-router-dom';
import React from 'react';
import { WhatWeDoImage } from '../../../../utils/Images';

const WhatWeDo = () => {
  return (
    <div className='container py-5 '>
      <div className='d-flex flex-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start align-items-start gap-2'>
        <div className='w-100 mb-5 mt-2 d-flex'>
          <img
            src={WhatWeDoImage}
            style={{ width: '85%' }}
            alt='ummah relief about image '
            className='mx-auto pt-smlg-2'
          />
        </div>
        <div className='w-100 mb-4'>
          <h3 className='mb-2 mb-xxl-5 fw-bold'>What We Do</h3>
          <p className='fw-bold tri-card-p' style={{ textAlign: 'justify' }}>
            Your compassionate support can make a profound difference in the lives of orphans, widows,
            senior citizens, the medically disabled, and those who struggle to afford a single meal, access necessary medicine, or drink clean water.
          </p>
          <p style={{ textAlign: 'justify' }} className='tri-card-p'>
            Established in 1994, Ummah Relief International is a dedicated humanitarian organization with a mission to assist those in need across the globe.
            Our comprehensive relief efforts encompass vital services, including the provision of food, clean water, clothing, shelter, access to medical treatment,
            and the establishment of educational facilities.
          </p>
          <Link
            to='/donations/oneTime'
            className='text-uppercase btn btn-light text-success fw-bold px-4 tri-card-p border'
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '12px',
            }}
          >
            Donate Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
