import React from 'react';
import { FacebookLogo } from '../../../utils/Images';
import { Link, useNavigate } from 'react-router-dom';
import { getAssetUrl } from '../../../utils/constants';
import useScreen from '../../../hooks/useScreen';

function Top3Donations({ top3CausesData = [] }) {
  const { width } = useScreen();
  const navigate = useNavigate();

  return (
    <div className="container my-5 pt-3 h-100 d-block">
      <div className="divider mb-4">
        <span className="mx-4 fs-4 fw-bold text-main">
          Make a Difference
        </span>
      </div>

      <div className="row mb-5">
        {Array.isArray(top3CausesData) &&
          top3CausesData.map((item, index) => {
            if (width < 1200 && width > 990 && index > 2) {
              return;
            }
            return (
              <div
                key={index}
                className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 pb-sm-4"
              >
                <div
                  className="card  h-100 d-flex flex-column"
                  style={{ borderRadius: '10px', position: 'relative' }}
                >
                  <div
                    className="dark-overlay-container "
                    style={{ borderRadius: '10px' }}
                  >
                    <img
                      src={item?.image}
                      className="image-overlay overflow-hidden"
                      alt={item?.altText || 'Card image cap'}
                      height={196}
                      // style={{ objectFit: "cover" }}
                      // max-height={196}
                    />
                    <div className="dark-overlay" />
                  </div>

                  {/* <div
                    className="dull-item w-100"
                    style={{ height: "196px", position: "absolute" }}
                  /> */}

                  <Link
                    to={`/causes/details/${item?.id}`}
                    style={{
                      textDecoration: 'none',
                      borderRadius: '10px',
                      minHeight: 80,
                      fontWeight: 600,
                    }}
                    className="mt-4 px-4 fs-4 text-center text-main top-3-cause-title"
                  >
                    {item?.title || ''}
                  </Link>

                  <p
                    className="px-4 card-text text-center"
                    style={{ fontWeight: 600 }}
                  >
                    {item?.caption || ''}
                  </p>

                  <div className="pb-4 mt-auto ">
                    {/* <div>
                      <div
                        className="fs-4 text-center text-main"
                        style={{ fontWeight: 600 }}
                      >
                        {item?.title || ""}
                      </div>
                      <p
                        className="card-text text-center"
                        style={{ fontWeight: 600 }}
                      >
                        {item?.caption || ""}
                      </p>
                    </div> */}
                    {/* <div className="divider-thin my-1" /> */}
                    <div className="hidden-button mt-auto p-2">
                      <a
                        onClick={() =>
                          navigate(`/causes/donation/${item.id}`, {
                            replace: true,
                            state: {
                              amount: 0,
                              cause: { cause_title: item?.title || '' },
                            },
                          })
                        }
                        className="bg-main fs-6 px-3 py-2 text-white d-block text-center fw-bold btn-main"
                        style={{
                          textDecoration: 'none',
                          borderRadius: '10px',
                          backgroundColor: '#18642f',
                          whiteSpace: 'nowrap',
                          cursor: 'pointer',
                        }}
                      >
                        Donate Now
                      </a>
                    </div>
                    {/* <div>
                      <div className="divider-thin my-3" />

                      <div className="text-center text-uppercase fw-bold text-secondary">
                        zakat | Sadaqah | Lillah
                      </div> 
                    </div> */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Link
        to={`/causes/1`}
        style={{ textDecoration: 'none', borderRadius: '10px' }}
        className="bg-main fs-5 px-4 py-2 text-white w-100 d-block text-center fw-bold btn-main"
      >
        More Causes You Can Support
      </Link>
    </div>
  );
}

export default Top3Donations;
