import { Link, a } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import FormValidator from '../utils/validation';
import SubscriptionService from '../services/subscription.service';
import Swal from 'sweetalert2';
import WebService from '../services/web.service';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { format } from 'crypto-js';
import { themeConfig } from '../config/theme.config';
import { useNavigate } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import { LocationIcon, MainIcon, PhoneCallIcon } from '../utils/Images';
import { AppContext } from '../layouts/WebsiteLayout';

const WebsiteFooter = () => {
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [logo, setLogo] = useState({});
  const [location, setLocation] = useState('');
  const [siteEmail, setSiteEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [uanNumber, setUanNumber] = useState('');
  const [mobileNumber, setMobileNumner] = useState('');
  const [facebook, setFacebook] = useState({});
  const [twitter, setTwitter] = useState({});
  const [linkedin, setLinkedIn] = useState({});
  const [youtube, setYoutube] = useState({});
  const [siteDescription, setSiteDescription] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const { websiteSettings } = useContext(AppContext);

  const handleSubscription = (e) => {
    setisLoading(true);
    e.preventDefault();
    SubscriptionService.addSubscription(email)
      .then((data) => {
        setisLoading(false);
        setMessage(data.message);
        setEmail('');
      })
      .catch((err) => {
        setisLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Subscribe',
          text: 'Got Error!',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      });
  };

  const getWebSettings = (res) => {
    // console.log(res);
    setLogo(res.web_logo);

    const webInfo = res.web_info;

    for (let i = 0; i < webInfo.length; i++) {
      if (webInfo[i].setting === 'uan') {
        var ph = webInfo[i].value ? webInfo[i].value.replaceAll('-', '') : '';
        // console.log(ph);
        var formatedNumber = ph.replace(
          /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)/,
          '$1-$2-$3-$4'
        );
        setUanNumber(formatedNumber);
      }
      if (webInfo[i].setting === 'phoneNumber') {
        var ph = webInfo[i].value ? webInfo[i].value.replaceAll('-', '') : '';
        var formatedNumber = ph.replace(
          /([0-9]{3})([0-9]{3})([0-9]+)/,
          '$1-$2-$3'
        );

        setPhoneNumber(formatedNumber);
      }
      if (webInfo[i].setting === 'mobile') {
        var ph = webInfo[i].value ? webInfo[i].value.replaceAll('-', '') : '';
        var formatedNumber = ph.replace(
          /([0-9]{3})([0-9]{3})([0-9]+)/,
          '$1-$2-$3'
        );
        setMobileNumner(formatedNumber);
      }
      if (webInfo[i].setting === 'siteTitle') {
        setSiteTitle(webInfo[i].value);
      }
      if (webInfo[i].setting === 'address') {
        setLocation(
          webInfo[i].value.replace('Ummah Relief International</br>', '')
        );
      }
      if (webInfo[i].setting === 'postalAddress') {
        setPostalAddress(
          webInfo[i].value.replace('Ummah Relief International<br />', '')
        );
      }
      if (webInfo[i].setting === 'emailAddress') {
        setSiteEmail(webInfo[i].value);
      }
      if (webInfo[i].setting === 'siteDescription') {
        setSiteDescription(webInfo[i].value);
      }
    }
    const social = res.web_social;
    for (let i = 0; i < social.length; i++) {
      if (social[i].setting === 'Facebook') {
        // console.log('location: ', social[i]);
        setFacebook(social[i]);
      }
      if (social[i].setting === 'Twitter') {
        setTwitter(social[i]);
      }
      if (social[i].setting === 'LinkedIn') {
        setLinkedIn(social[i]);
      }
      if (social[i].setting === 'Youtube') {
        setYoutube(social[i]);
      }
    }
  };

  useEffect(() => {
    websiteSettings && getWebSettings(websiteSettings);
  }, [websiteSettings]);

  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  }, [message]);

  return (
    <footer
      className="text-white"
      style={{ backgroundColor: themeConfig.dark }}
    >
      <div className="container">
        <footer className="py-5 pb-0">
          <div className="row py-2 px-3 px-md-0 px-lg-0 px-xl-0">
            <div className="col-12  col-md-12 col-lg-5 col-xl-5 mb-3  relative-margin-flogo">
              <h5 className="text-uppercase my-2 text-white">
                <img
                  src={
                    logo.logo
                      ? `data:image/${logo.logo_ext};base64,${logo.logo}`
                      : 'https://ummahrelief.org/admin/public/assets/admin/uploads/logo/logo_light_default.png'
                  }
                  alt="Ummah Relief Logo"
                  className="site-logo"
                  onClick={() => navigate('/')}
                />
              </h5>
              <p
                className="text-white footer-text text-justify"
                style={{ fontSize: '16px' }}
              >
                {siteDescription ||
                  'The most impactful way to support Ummah Relief International is through a financial contribution. Your generosity empowers Ummah Relief International to provide assistance every day of the year, ensuring help reaches those in need, precisely when and where it is needed most.'}
              </p>
              {/*  <ul className="list-unstyled d-flex content-sm-center">
                <li className="tri-card-p">
                  <a className="link-dark" href={twitter.value} target="_blank">
                    <i
                      className={`fab  fa-twitter text-white`}
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                </li>
                <li className="ms-3 tri-card-p">
                  <a
                    className="link-dark"
                    href={facebook.value}
                    target="_blank"
                  >
                    <i
                      className="fab fa-facebook text-white"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                </li>
                <li className="ms-3 tri-card-p">
                  <a
                    className="link-dark"
                    href={linkedin.value}
                    target="_blank"
                  >
                    <i
                      className=" fa-brands fa-linkedin-in  text-white"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                </li>
                <li className="ms-3 tri-card-p">
                  <a className="link-dark" href={youtube.value} target="_blank">
                    <i
                      className=" fa-brands fa-youtube text-white"
                      style={{ fontSize: 20 }}
                    ></i>
                  </a>
                </li>
              </ul> */}
              <SocialLinks />
            </div>

            <div className="col-12  col-md-12 col-lg-4 col-xl-4 mb-3  pe-md-4 footer">
              {/* <h5 className="text-uppercase mb-4 text-white fs-3 fw-bold text-center"></h5> */}
              <h3>CONTACT US</h3>
              <ul className="nav flex-column text-white tri-card-p">
                <h5>Telephone Number</h5>
                <li className="nav-item mb-2">
                  <div className=" d-flex gap-3 justify-content-start align-items-center">
                    <div className="col-1 text-start">
                      <div
                        className="bg-main p-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <img
                          src={PhoneCallIcon}
                          style={{ height: '22px', width: '22px' }}
                        />
                      </div>
                    </div>

                    <div className="col-11 d-flex flex-column gap-1">
                      {/* <div className="fs-5">
                        <strong>Telephone Number</strong>
                      </div> */}
                      <a href={`tel: ${uanNumber || '1-800-713-4482'}`}>
                        {uanNumber || '1-800-713-4482'}
                      </a>
                      <a href={`tel: ${mobileNumber || '847-622-0574'}`}>
                        {mobileNumber || '847-622-0574'}
                      </a>
                      <a href={`tel: ${phoneNumber || '847-741-3816'}`}>
                        {phoneNumber || '847-741-3816'}
                      </a>
                    </div>
                  </div>
                </li>
                <h5>Email Address</h5>
                <li className="nav-item mb-2">
                  <div className=" d-flex gap-3 justify-content-start align-items-center">
                    <div className="col-1 text-start">
                      <div
                        className="bg-main p-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <img
                          src={MainIcon}
                          style={{ height: '22px', width: '22px' }}
                        />
                      </div>
                    </div>

                    <div className="col-11 d-flex flex-column gap-1">
                      {/* <div className="fs-5">
                        <strong>Email</strong>
                      </div> */}

                      <a
                        href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
                      >
                        {siteEmail || 'Ummah@ummahrelief.org'}
                      </a>
                    </div>
                  </div>
                </li>
                <h5>Location</h5>
                <li className="nav-item mb-2">
                  <div className=" d-flex gap-3 justify-content-start align-items-center">
                    <div className="col-1 text-start">
                      <div
                        className="bg-main p-2 d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: '50%',
                          height: '40px',
                          width: '40px',
                        }}
                      >
                        <img
                          src={LocationIcon}
                          style={{ height: '22px', width: '22px' }}
                        />
                      </div>
                    </div>

                    <div className="col-11 d-flex flex-column gap-1">
                      {/* <div className="fs-5">
                        <strong>Location</strong>
                      </div> */}
                      <address
                        dangerouslySetInnerHTML={{
                          __html:
                            location ||
                            '339 W River Rd, Elgin, IL 60123,<br>United States',
                        }}
                      />
                      <address
                        dangerouslySetInnerHTML={{
                          __html:
                            postalAddress ||
                            'P.O. Box 1426, Elgin, IL 60121,<br>United States',
                        }}
                      />
                    </div>
                  </div>
                </li>

                {/*  <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-start ">
                  <div className="col-1 text-start">
                    <i className="fas fa-location-pin"></i>
                  </div>
                  <div className="col-11">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          postalAddress ||
                          'P.O. Box 1426, Elgin, IL 60121,<br>United States',
                      }}
                    />
                  </div>
                </li> */}
                {/* <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                  <div className="col-1 text-start">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="col-11">
                    <a
                      className="text-decoration-none text-white"
                      href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
                    >
                      {siteEmail || 'Ummah@ummahrelief.org'}
                    </a>
                  </div>
                </li> */}

                {/* <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                  <div className="col-1 text-start">
                    <i className="fas fa-phone"></i>
                  </div>
                  <div className="col-11">
                    <a
                      className="text-decoration-none text-white"
                      href={`tel: ${uanNumber || '1-800-713-4482'}`}
                    >
                      {uanNumber || '1-800-713-4482'}
                    </a>
                  </div>
                </li>
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                  <div className="col-1 text-start">
                    <i className="fas fa-mobile"></i>
                  </div>
                  <div className="col-11">
                    <a
                      className="text-decoration-none text-white"
                      href={`tel: ${mobileNumber || '847-622-0574'}`}
                    >
                      {mobileNumber || '847-622-0574'}
                    </a>
                  </div>
                </li>
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-center">
                  <div className="col-1 text-start">
                    <i className="fas fa-fax"></i>
                  </div>
                  <div className="col-11">
                    <a
                      className="text-decoration-none text-white"
                      href={`tel: ${phoneNumber || '847-741-3816'}`}
                    >
                      {phoneNumber || '847-741-3816'}
                    </a>
                  </div>
                </li> */}
              </ul>
            </div>

            <div className="col-12  col-md-12 col-lg-3 col-xl-3 mb-3  footer">
              {/* <h5 className="mt-4 text-uppercase mb-2 text-white fs-3 fw-bold text-center">
                Policies
              </h5> */}
              <h3>POLICIES</h3>
              <ul className="nav flex-column text-white tri-card-p">
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-start ">
                  <div className="col-11">
                    <a href="/policies">Terms</a>
                  </div>
                </li>
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-start ">
                  <div className="col-11">
                    <a href="/policies">Privacy</a>
                  </div>
                </li>
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-start ">
                  <div className="col-11">
                    <a href="/policies">Cookies</a>
                  </div>
                </li>
                <li className="nav-item mb-2 d-flex gap-3 justify-content-start align-items-start ">
                  <div className="col-11">
                    <a href="/policies">Sanction Policy</a>
                  </div>
                </li>
              </ul>

              <form onSubmit={(e) => handleSubscription(e)}>
                {/* <h5 className="text-uppercase mb-4 text-white fs-3 fw-bold text-center">
                  NEWSLETTER SIGN UP
                </h5> */}
                <h3> NEWSLETTER SIGN UP</h3>
                <div className="w-100 d-flex flex-column gap-3">
                  {message !== '' && (
                    <div className="alert alert-info" role="alert">
                      {message}
                    </div>
                  )}
                  <label
                    htmlFor="subscription_email"
                    className="visually-hidden tri-card-p"
                  >
                    Email address
                  </label>
                  <p>
                    Sign Up to receive updates about appeals, the work and more.
                  </p>
                  <div className="input-group">
                    <input
                      id="subscription_email"
                      type="email"
                      className="form-control tri-card-p"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={(e) =>
                        setError(
                          FormValidator.email(e.target.value, e.target.id)
                        )
                      }
                      placeholder="Email address"
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                  {error !== '' && (
                    <small className="text-danger">{error}</small>
                  )}
                  <div className="">
                    <button
                      className="text-uppercase btn-main border-0 text-white py-2 px-4 fw-bold rounded-2 icon-shadow"
                      disabled={email === '' || error !== '' || isLoading}
                      type="submit"
                    >
                      {isLoading ? 'SIGNING UP...' : 'SIGN UP'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row flex-lg-row flex-xl-row justify-content-center align-items-center py-1 my-1">
            <p className="copy-right">COPYRIGHT © 2024. ALL RIGHTS RESERVED.</p>
          </div>
        </footer>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
