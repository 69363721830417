import React from "react";
import { useState } from "react";
import InputMask from "react-input-mask";
import {
  MasterCard,
  UnionPayCard,
  JcbCard,
  DiscoverCard,
  AmericanExpressCard,
  VisaCard,
  UnkownCard,
  VisaCardLogo,
  MasterCardLogo,
  AmericanExpressCardLogo,
  DiscoverCardLogo,
  UnionPayCardLogo,
} from "../utils/Images";

const MaskedInput = (props) => {
  const cardImage = () => {
    // console.log(props.cardType)

    if (props.cardType == "visa") {
      return VisaCardLogo;
    } else if (props.cardType == "master-card") {
      return MasterCardLogo;
    } else if (props.cardType == "american-express") {
      return AmericanExpressCardLogo;
    } else if (props.cardType == "discover") {
      return DiscoverCardLogo;
    } else if (props.cardType == "jcb") {
      return JcbCard;
    } else if (props.cardType == "unionpay") {
      return UnionPayCardLogo;
    }
    return UnkownCard;
  };

  return (
    <>
      <div className="">
        {props.label && (
          <label htmlFor={props.id} className="form-label text-dark">
            {props.label}{" "}
            {props.required ? <span className="text-danger">*</span> : <></>}
          </label>
        )}

        <InputMask
          mask={props.mask}
          value={props.value}
          onChange={props.onChange}
          onInput={props.onInput}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          alwaysShowMask={false}
          maskChar=""
        >
          {(inputProps) => (
            <div className="d-flex input-group focus-group">
              <input
                className="form-control fc"
                id={props.id}
                min={props.min}
                max={props.max}
                {...inputProps}
                required={props.required}
                placeholder={props.placeholder}
                name={props.name}
              />
              {/* {
                <span className="input-group-text ">
                  {props.isCreditCardNumber && props.isCreditCardNumber && (
                    <img
                      src={cardImage()}
                      alt={props.cardType}
                      className="credit-card-type-input-img cursor-pointer p-1"
                      id="creditCardMask2"
                    />
                  )}
                </span>
              } */}
            </div>
          )}
        </InputMask>
      </div>
    </>
  );
};

export default MaskedInput;
