import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components";
import GalleryComponent from "./components/GalleryComponent";
import GalleryService from "../../services/gallery.service";
import ImageGallery from "react-image-gallery";

const breadcrumbItems = [
  {
    title: "Home",
    route: "/",
    isLast: false,
  },
  {
    title: "Gallery",
    route: "/",
    isLast: true,
  },
];

const Gallery = () => {
  const [images, setImages] = useState();
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const getImages = () => {
    GalleryService.getImages(1).then((data) => {
      let img = data.data.map((item) => {
        return {
          original: `${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/gallery/${item.image}`,
          // original: `${item.image}`,
          thumbnail: `${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/gallery/${item.image}`,
          // thumbnail: `${item.image}`,
        };
      });
      setFrom(data.from);
      setTo(data.to);
      setTotal(data.total);
      setPerPage(data.per_page);
      setImages(img);
    });
  };

  useEffect(() => {
    document.title = "Gallery | Ummah Relief";
    getImages();
    scrollTop();
  }, []);

  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title="Gallery" />
      <GalleryComponent images={images} />
    </>
  );
};

export default Gallery;
