import API from "../utils/api";

const webSetting = () => {
    return API.get('/web-settings')
        .then(response => {
            return response.data
        })
}

const WebService = {
    webSetting
}

export default WebService