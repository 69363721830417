import { Card, CardBody } from "reactstrap";
import {
  FlameIcon,
  Globe,
  HandShake,
  VisionImg,
  WaterDrop,
} from "../../../../utils/Images";

import React from "react";
import { themeConfig } from "../../../../config/theme.config";

const VisionGoal = () => {
  return (
    <div className="mt-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      I. Introduction
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      Ummah Relief International is committed to protecting the
                      privacy of our donors, volunteers, beneficiaries, and
                      website visitors. This Privacy Policy outlines our
                      practices regarding the collection, use, and protection of
                      your personal information.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    {/* <i className="fa-solid fa-fire-flame-curved" style={{fontSize: 32}}></i> */}
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      II. Information Collection
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      We collect personal information such as names, contact
                      details, and payment information, primarily through
                      donation forms and website interactions. Additional
                      information may be gathered through direct communications
                      and event participation.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      III. Use of Information
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      Your information is used to process donations, communicate
                      updates, and administer our programs. It also helps us
                      improve our services and fulfill our mission effectively.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      IV. Information Sharing and Disclosure
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      We do not sell or rent personal information. Information
                      may be shared with trusted third-party partners for
                      operational purposes or as required by law. All partners
                      are vetted for compliance with our privacy standards.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      V. Data Security
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      We employ a range of security measures to protect your
                      personal information from unauthorized access, alteration,
                      or destruction. These include secure data storage
                      practices and encrypted communication channels.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      VI. Your Rights and Choices
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      You have the right to access, correct, or request deletion
                      of your personal information. You can also object to or
                      restrict certain processing activities. To exercise these
                      rights, please contact us using the details provided
                      below.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      VII. Cookies and Tracking Technologies
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      Our website uses cookies and similar tracking technologies
                      to enhance user experience and analyze website traffic.
                      You can manage your cookie preferences through your
                      browser settings.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      VIII. Policy Updates
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      We may update this Privacy Policy periodically to reflect
                      changes in our practices or legal obligations. We will
                      notify you of any significant changes and post the updated
                      policy on our website.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      IX. Contact Information
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-start">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      For any questions or concerns regarding this Privacy
                      Policy or your personal information, please contact us at:
                    </p>
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      <b>Email:</b> ummah@ummahrelief.org
                    </p>
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      <b>Address:</b> 339 W River Rd, Elgin IL 60123{" "}
                    </p>
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      <b>Mailing Address:</b> P.O. Box 1426, Elgin, IL 60121
                    </p>
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      <b>Phone:</b> 1800-713-4482
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 my-0 my-lg-4">
            <Card className="border-0 rounded-0" color="transparent">
              <CardBody>
                <div className="d-flex flex-column justify-content-start align-items-start gap-4 py-3 px-2">
                  <div className="d-flex flex-row align-items-start w-100 justify-content-start align-items-center gap-3">
                    <span className="fw-bold" style={{ fontSize: 22 }}>
                      X. Acknowledgment and Consent
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <p className="tri-card-p" style={{ textAlign: "justify" }}>
                      By using our services and website, you acknowledge that
                      you have read this Privacy Policy and consent to the
                      collection and use of your personal information as
                      described.
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionGoal;
