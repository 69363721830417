import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import { FavIcon } from "../../../../utils/Images";

const BlogCard = ({ blog }) => {
  return (
    <div
      className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 wow fadeInUp2  animated  pb-4"
      data-wow-delay=".1s"
      style={{
        visibility: "visible",
        animationDelay: "0.1s",
        animationName: "fadeInUp2",
      }}
    >
      <div className="h-100 d-flex flex-column blogs">
        <div className="">
          <div className="blogs__thumb pos-rel mb-45">
            <div className="blogs__thumb--img">
              <Link
                to={`/blogs/details/${blog.id}`}
                className="text-decoration-none tri-card-p"
              >
                {/*<img src={`data:image/png;base64, ${blog.image_base64}`}*/}
                {/*     style={{width: '100%', minHeight: '250px', maxHeight: '250px'}} alt=""/>*/}
                <img
                  // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/blog/${blog.image}`}
                  src={`${blog.image}`}
                  style={{
                    width: "100%",
                    minHeight: "250px",
                    maxHeight: "250px",
                  }}
                  alt="blog"
                />
              </Link>
            </div>
            <a className="blog-tag text-decoration-none ">
              <LinesEllipsis
                text={blog.categories.category}
                maxLine="2"
                ellipsis=""
                trimRight
                basedOn="letters"
              />
              {/* {blog.categories.category} */}
            </a>
          </div>
        </div>
        <div className="h-100">
          <div className="blogs__content h-100">
            <div className="d-flex flex-column align-items-start justify-content-between h-100">
              <div>
                <div className="blogs__content--meta mb-15">
                  <span className="">
                    <i className="far fa-calendar-alt" />{" "}
                    {moment(blog.created_at).format("DD MMM YYYY")}
                  </span>
                  <span>
                    <i className="far fa-comment tri-card-p" /> Comment (
                    {blog.comment_count})
                  </span>
                </div>
                {/*style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}*/}

                <h4 className="mb-20">
                  <Link
                    to={`/blogs/details/${blog.id}`}
                    className="text-decoration-none tri-card-p"
                  >
                    {blog.title}
                    {/* <LinesEllipsis
                text={blog.title}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              /> */}
                  </Link>
                </h4>
              </div>
              <div>
                <ul className="project-manager p-0">
                  <li>
                    <img src={FavIcon} alt="" width={25} height={25} />
                    <span className="mx-2 tri-card-p">Admin</span>
                  </li>
                  <li>
                    <Link
                      className="more_btn_02 text-decoration-none tri-card-p"
                      to={`/blogs/details/${blog.id}`}
                    >
                      Read more <i className="fas fa-arrow-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
