import React, { useEffect, useState } from "react";
import CauseCard from "./CauseCard";
import CauseService from "../../../../services/causes.service";
import { useParams } from "react-router-dom";
import { PaginationComponent } from "../../../../components";
import { LogoLoader } from "../../../../utils/Images";

const CauseList = () => {
  const [causes, setCauses] = useState([]);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const params = useParams();
  const getAllCauses = () => {
    setCauses([]);
    CauseService.getCauses(params.pageNo).then((res) => {
      setCauses(res.data);
      setFrom(res.from);
      setTo(res.to);
      setTotal(res.total);
      setPerPage(res.per_page);
    });
  };

  useEffect(() => {
    getAllCauses();
  }, [params]);

  return (
    <div className="container my-5">
      <h2 className="text-center my-5">
        Help Ummah Relief with Sadaqa/Sadaqa Jariah distribution programs
      </h2>
      {causes.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={LogoLoader} alt="" />
          <h3>Loading...</h3>
        </div>
      ) : (
        <>
          <div className="causes-wrap row h-100">
            {causes &&
              causes.map((cause, index) => {
                return <CauseCard cause={cause} key={index} className="h-100" />;
              })}
          </div>
          <div className="d-flex flex-column  flex-lg-row justify-content-between align-items-center">
            <span className="m-3 mb-lg-0 mb-xl-0 mb-xxl-0">
              Showing <strong>{from}</strong> to <strong>{to}</strong> of{" "}
              <strong>{total}</strong> entries
            </span>
            <PaginationComponent route="causes" total={total / perPage} />
          </div>
        </>
      )}
    </div>
  );
};

export default CauseList;
