import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {WebsiteRoutes} from "./routes/app.route";
import {WebsiteLayout} from "./layouts";
import {themeConfig} from "./config/theme.config";



const App = () => {
    useEffect(() => {
        themeConfig.changeDarkMode()
    }, [])

    const websiteRoute = WebsiteRoutes.map(({path, Component, children}, key) => {
        if (children) {
            return (
                <Route exact element={<Component/>} key={key}>
                    {children.map(({childPath, ChildComponent}, childKey) => (
                        <Route exact path={childPath} element={<ChildComponent/>} key={childKey}/>
                    ))}
                </Route>
            )
        } else {
            return (<Route exact path={path} element={<Component/>} key={key}/>)

        }
    })

    // console.log(appRoute)
    return (
        <BrowserRouter>
            <Routes>
                <Route exact element={<WebsiteLayout/>}>
                    {websiteRoute}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
