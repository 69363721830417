import React, { useEffect, useState } from "react";
import moment from "moment";
import { DonationBg, FavIcon, LogoLoader } from "../../../../utils/Images";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
/* import { Tooltip } from "reactstrap"; */
import { SocialMediaShare } from "../../../../components";
import LinesEllipsis from "react-lines-ellipsis";
import CommentService from "../../../../services/comment.service";
import FormValidator from "../../../../utils/validation";
import Swal from "sweetalert2";
import BlogService from "../../../../services/blogs.service";
import Carousel from "react-material-ui-carousel";
import {
  Backdrop,
  Box,
  Card,
  Chip,
  Fade,
  Grid,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import MediaSlider from "../MediaSlider";

const DetailComponent = ({ blog, categories, recentBlog, relatedBlogs }) => {
  const blogImagesAndVideosArray = blog?.post_media || [];
  const params = useParams();
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [mainCategoryTooltipOpen, setMainCategoryTooltipOpen] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [commentCount, setCommentCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [blogId, setBlogId] = useState(
    params && params.blogId && params.blogID
  );
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const formObj = {
      full_name: fullName,
      email: email,
      comment: comment,
      blog_id: blog.id,
    };
    CommentService.storeComment(formObj)
      .then((data) => {
        setCommentCount(data.count);
        setComments(data.comments);
        setFullName("");
        setEmail("");
        setComment("");
        Swal.fire({
          icon: "success",
          title: "Comment",
          text: "Comment posted Successfuly!",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error while posting comment!",
        });
      });
  };

  const getAllCommentsOfBlog = () => {
    CommentService.getComments(blog.id).then((data) => {
      // console.log(data)
      setCommentCount(data.count);
      setComments(data.comments);
    });
  };

  useEffect(() => {
    getAllCommentsOfBlog();
  }, [blog]);

  //   const getAllCommentsOfBlog = () => {
  //     BlogService.getComments(blogId).then((res) => {
  //         setComments()
  //     });
  //   };

  //   useEffect(() => {
  //     getAllCommentsOfBlog();
  //   }, []);

  // console.log(relatedBlogs)

  const [open, setOpen] = React.useState(false);
  const [modalData, setModatData] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: "snap",
    rtl: false,
    slides: { perView: "auto", spacing: 10 },
  });

  return (
    <section className="blog-details-area grey-bg pt-130 pb-100">
      <div className="container">
        {Object.keys(blog).length > 0 ? (
          <div className="row">
            <div className="col-xl-8 col-lg-7 col-md-12 mb-40">
              <div className="blogs__thumb--img white-bg pb-25">
                {/*<img src={`data:image/png;base64, ${blog.image_base64}`} alt=""/>*/}
                {blog?.image && (
                  <img
                    // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/blog/${blog.image}`}
                    src={`${blog.image}`}
                    alt="blog cover"
                  />
                )}
              </div>
              <div className="blogs-details-content-area white-bg">
                <div
                  className="blogs blogs-03 white-bg wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <div className="blogs__thumb pos-rel mb-35">
                    <Tooltip
                      title={`Search causes/blogs by this category`}
                      arrow
                      placement="top"
                    >
                      <NavLink
                        className="blog-tag text-decoration-none"
                        // className="text-uppercase btn btn-outline-light text-white bg-success text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg "
                        // style={{
                        //   borderRadius: "20px",
                        //   borderWidth: "2px",
                        //   fontSize: "12px",
                        //   left:0
                        // }}
                        style={{ left: 0 }}
                        id="blog_category_tooltip"
                        to={`/search/category/${blog.category.id}`}
                      >
                        {blog.category ? blog.category.category : ""}
                      </NavLink>
                    </Tooltip>
                  </div>
                  <div className="blogs__content blogs-03__content">
                    <h3 className="mb-20">{blog.title}</h3>
                    <div className="blogs__content--meta">
                      <span className="tri-card-p">
                        <i className="fas fa-user-circle" />{" "}
                        {blog["author_name"]}
                      </span>
                      <span className="tri-card-p">
                        <i className="fas fa-calendar-alt" />{" "}
                        {moment(blog.created_at).format("DD MMM YYYY")}
                      </span>
                      <span className="tri-card-p">
                        <i className="fas fa-comment" /> Comment ({commentCount}
                        )
                      </span>
                      {/*<span>*/}
                      {/*  <i className="fas fa-heart"/> Like (1K)*/}
                      {/*</span>*/}
                    </div>
                  </div>
                </div>
                <p
                  className="tri-card-p"
                  dangerouslySetInnerHTML={{ __html: blog.body }}
                ></p>
                {Array.isArray(blogImagesAndVideosArray) &&
                  blogImagesAndVideosArray.length > 0 && (
                    <MediaSlider media={blogImagesAndVideosArray || []} />
                  )}

                <div
                  className="blog-post-tag wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <div className="row pt-3">
                    <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                      <div className="post-tag-list mb-50 tri-card-p d-flex gap-3">
                        <span>Tags: </span>
                        <Stack
                          direction="row"
                          spacing={1}
                          flexWrap="wrap"
                          useFlexGap
                          className="mt-1"
                        >
                          {/* {console.log('blog=>', blog)} */}
                          {blog.tags.map((tag, index) => (
                            <>
                              <Tooltip
                                title="Search causes/blogs by this tag"
                                arrow
                                placement="top"
                              >
                                {/* <NavLink
                                to={`/search/tag/${tag.name}`}
                                className="text-decoration-none text-small"
                                id={`tag_tooltip_${index}`}
                                key={index}
                              > */}
                                <Chip
                                  sx={{
                                    height: "auto",
                                    "& .MuiChip-label": {
                                      display: "block",
                                      whiteSpace: "normal",
                                    },
                                  }}
                                  component="a"
                                  href={`/search/tag/${tag.name}`}
                                  label={
                                    <Typography
                                      variant="subtitle2"
                                      display="block"
                                    >
                                      {tag.name}
                                    </Typography>
                                  }
                                  size="small"
                                  clickable
                                  //  style={{size:'10px'}}
                                />

                                {/* </NavLink> */}
                              </Tooltip>
                            </>
                          ))}
                        </Stack>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <div className="post-share-icon text-md-right mb-50 d-flex tri-card-p">
                        <span>Share : </span>
                        <div className="d-flex gap-2">
                          <Tooltip
                            title="Share to facebook"
                            arrow
                            placement="top"
                          >
                            <div>
                              <SocialMediaShare platform="facebook" />
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="Share to messenger"
                            arrow
                            placement="top"
                          >
                            <div>
                              <SocialMediaShare platform="messenger" />
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="Share to twitter"
                            arrow
                            placement="top"
                          >
                            <div>
                              <SocialMediaShare platform="twitter" />
                            </div>
                          </Tooltip>
                          <Tooltip
                            title="Share to whatsapp"
                            arrow
                            placement="top"
                          >
                            <div>
                              <SocialMediaShare platform="whatsapp" />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="blog-post-area mb-45  wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <div className="row ">
                    {relatedBlogs.length > 0 ? (
                      relatedBlogs.map((blog, index) => (
                        <div
                          className="col-6 col-xl-6 col-lg-12 col-md-6 "
                          key={index}
                        >
                          <div className="post d-sm-flex align-items-center mb-1   mb-md-5 mb-xxl-50 mb-xl-50 mb-lg-50 ">
                            <div className="post__img mr-20">
                              {blog?.image && (
                                <img
                                  // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/blog/${blog.image}`}
                                  src={`${blog.image}`}
                                  width={80}
                                  height={80}
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="post__text">
                              <h5>
                                <a
                                  href={`/blogs/details/${blog.id}`}
                                  className="text-decoration-none"
                                >
                                  <LinesEllipsis
                                    text={blog.title}
                                    className="sm-font"
                                    maxLine="2"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </a>
                              </h5>
                              <div className="post__text-meta sm-hidden">
                                <span className="pe-3">
                                  <i className="fas fa-user " /> By Admin
                                </span>
                                <span>
                                  <i className="fas fa-calendar-alt" />{" "}
                                  {moment(blog.created_at).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <h3>Loading...</h3>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="post-comments mb-50 wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <h4 className="comments-title mb-40">People’s Comments</h4>
                  <ul className="latest-comments">
                    {/*<li>*/}
                    {/*    <div className="comments-box">*/}
                    {/*        <div className="comments__avatar">*/}
                    {/*            <img*/}
                    {/*                src="https://www.devsnews.com/template/fande/fande/assets/img/blog/07.png"*/}
                    {/*                alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className="comments__content fix">*/}
                    {/*            <h5>David Angel Makel</h5>*/}
                    {/*            <span>IT Consultant</span>*/}
                    {/*            <p>*/}
                    {/*                It is a long established fact that a reader will be*/}
                    {/*                distracted by the readable content page looking at its*/}
                    {/*                layout point of using normal*/}
                    {/*            </p>*/}
                    {/*            <a className="com-btn" href="#">*/}
                    {/*                Reply Comments <i className="fas fa-arrow-right"/>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li className="children">*/}
                    {/*    <div className="comments-box">*/}
                    {/*        <div className="comments__avatar">*/}
                    {/*            <img*/}
                    {/*                src="https://www.devsnews.com/template/fande/fande/assets/img/blog/08.png"*/}
                    {/*                alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div className="comments__content fix">*/}
                    {/*            <h5>David Angel Makel</h5>*/}
                    {/*            <span>IT Consultant</span>*/}
                    {/*            <p>*/}
                    {/*                It is a long established fact that a reader will be*/}
                    {/*                distracted by the readable content page looking at its*/}
                    {/*                layout point of using normal*/}
                    {/*            </p>*/}
                    {/*            <a className="com-btn" href="#">*/}
                    {/*                Reply Comments <i className="fas fa-arrow-right"/>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</li>*/}

                    {comments &&
                      comments.map((item, index) => (
                        <li key={index}>
                          <div className="comments-box">
                            <div className="comments__avatar">
                              <img
                                src={FavIcon}
                                width={50}
                                height={50}
                                alt=""
                              />
                            </div>
                            <div className="comments__content fix">
                              <h5>{item.full_name}</h5>
                              {/*<span>IT Consultant</span>*/}
                              <p>{item.comment}</p>
                              {/*<a className="com-btn" href="#">*/}
                              {/*    Reply Comments <i className="fas fa-arrow-right"/>*/}
                              {/*</a>*/}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div
                  className="post-comments-form pb-40 wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <h4 className="comments-title mb-40">Reply Comments</h4>
                  <div className="post-form-area">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="input-text mb-30">
                            <input
                              type="text"
                              value={fullName}
                              id="name"
                              name="name"
                              onChange={(e) =>
                                setFullName(e.target.value.replace(/^\d+$/, ""))
                              }
                              className="form-control"
                              onBlur={(e) =>
                                setErrors({
                                  ...errors,
                                  name: FormValidator.isEmpty(
                                    e.target.value,
                                    e.target.id
                                  ),
                                })
                              }
                              placeholder="Full Name Here *"
                            />
                            {errors.name !== "" && (
                              <small className="text-danger">
                                {errors.name}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="input-text input-mail mb-30">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onBlur={(e) =>
                                setErrors({
                                  ...errors,
                                  email: FormValidator.email(
                                    e.target.value,
                                    e.target.id
                                  ),
                                })
                              }
                              className="form-control"
                              placeholder="Email Here *"
                            />
                            {errors.email !== "" && (
                              <small className="text-danger">
                                {errors.email}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="input-text input-msg mb-30">
                            <textarea
                              name="message"
                              id="message"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              onBlur={(e) =>
                                setErrors({
                                  ...errors,
                                  comment: FormValidator.isEmpty(
                                    e.target.value,
                                    e.target.id
                                  ),
                                })
                              }
                              cols={30}
                              rows={10}
                              placeholder="Comments *"
                              defaultValue={""}
                            />
                            {errors.comment !== "" && (
                              <small className="text-danger">
                                {errors.comment}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="comment-btn">
                            <button
                              // className="text-uppercase btn btn-outline-light theme_btn_background text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg "
                              // style={{
                              //   borderRadius: "20px",
                              //   borderWidth: "2px",
                              //   fontSize: "12px",
                              // }}
                              className={`  theme_btn theme_btn_bg text-white border-0 ${
                                (errors.name !== "" ||
                                  errors.email !== "" ||
                                  errors.comment !== "") &&
                                "comment-btn-disable"
                              }`}
                              type="submit"
                            >
                              send comments <i className="fas fa-arrow-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-12">
              <div className="blog-standard-right">
                <div
                  className="widget white-bg mb-40 wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <div className="widget-search-box">
                    <h4 className="widget-title mb-20">Search Here</h4>
                    <form
                      className="subscribe-form"
                      onSubmit={() => navigate(`/search/keywords/${keywords}`)}
                    >
                      <input
                        className="form-control"
                        type="text"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder="Keywords"
                      />
                      <button disabled={keywords === ""} type="submit">
                        <Link
                          to={`/search/keywords/${keywords}`}
                          style={{
                            pointerEvents: keywords === "" ? "none" : "",
                          }}
                          className="text-decoration-none text-success"
                        >
                          <i className="fas fa-search" />
                        </Link>
                      </button>
                    </form>
                  </div>
                </div>
                <div
                  className="widget mb-40 wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <ul className="widget-list p-0">
                    {/* categories  */}

                    {categories &&
                      categories.map((data, index) => (
                        <NavLink
                          to={`/search/category/${data.id}`}
                          className="text-decoration-none text-dark"
                          key={index}
                        >
                          <li className="category-link">
                            {data.category}
                            <span className="f-right">
                              <i className="fas fa-chevron-right" />
                            </span>
                          </li>
                        </NavLink>
                      ))}

                    {/*<li>*/}
                    {/*    IT Consulting{" "}*/}
                    {/*    <span className="f-right">*/}
                    {/*  <i className="fas fa-chevron-right"/>*/}
                    {/*</span>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    Product Engineering{" "}*/}
                    {/*    <span className="f-right">*/}
                    {/*  <i className="fas fa-chevron-right"/>*/}
                    {/*</span>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    Product Marketing{" "}*/}
                    {/*    <span className="f-right">*/}
                    {/*  <i className="fas fa-chevron-right"/>*/}
                    {/*</span>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    Education{" "}*/}
                    {/*    <span className="f-right">*/}
                    {/*  <i className="fas fa-chevron-right"/>*/}
                    {/*</span>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    Medical &amp; Health{" "}*/}
                    {/*    <span className="f-right">*/}
                    {/*  <i className="fas fa-chevron-right"/>*/}
                    {/*</span>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                <div
                  className="widget white-bg mb-40 pb-10 wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <h4 className="widget-title pt-40 mb-20 pl-35">
                    Recent Blog
                  </h4>
                  <ul className="post-list p-0">
                    {recentBlog ? (
                      <li>
                        <div className="post d-sm-flex align-items-center mb-20">
                          <div className="post__img mr-20">
                            {recentBlog?.image && (
                              <img
                                // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/blog/${recentBlog.image}`}
                                src={`${recentBlog.image}`}
                                width={80}
                                height={80}
                                alt=""
                              />
                            )}
                          </div>
                          <div className="post__text">
                            <h5>
                              <a
                                href={`/blogs/details/${recentBlog.id}`}
                                className="text-decoration-none"
                              >
                                {recentBlog.title}
                                {/* <LinesEllipsis
                                  text={recentBlog.title}
                                  maxLine="1"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="letters"
                                /> */}
                              </a>
                            </h5>
                            <span>
                              <i className="fas fa-chevron-right" /> By Admin
                            </span>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li>
                        <h5>Loading...</h5>
                      </li>
                    )}
                  </ul>
                </div>
                <div
                  className="widget wow fadeInUp2  animated"
                  data-wow-delay=".1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2",
                  }}
                >
                  <div
                    className="widget-donate-box pos-rel text-center"
                    style={{ backgroundImage: `url(${DonationBg})` }}
                  >
                    <h5>Donate Now</h5>
                    <h3>Want To Donate Our Product</h3>
                    <NavLink
                      className="theme_btn theme_btn_bg"
                      to="/donations/oneTime"
                      data-animation="fadeInLeft"
                      data-delay=".5s"
                    >
                      donate now <i className="fas fa-arrow-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={LogoLoader} alt="" />
            <h3>Loading...</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default DetailComponent;
