import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {PaymentElement} from '@stripe/react-stripe-js';
import PaymentService from '../services/payment.service';
import { render } from '@testing-library/react';
import { ElementsConsumer, useElements } from '@stripe/react-stripe-js/dist/react-stripe';
import CheckoutForm from './CheckoutForm';

// const CheckoutForm = () => {
//   const elements = useElements();
//   // console.log({elements})
//     return (

//       <PaymentElement />
 
//   );
// };


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// const stripePromise = loadStripe('pk_test_51MmCy8Ap9ZiDeaxc8LYRQGswibapEXmhUG7ZHtHxRZEgAlrwnKK8tK11fg9f5eTrZk45TpSmhOjSoKzp3oCebWRW00ey6zj2la');

const ECheckoutForm =  (props) =>{
  let clientSecret = null;
   (async () => {
    // console.log({apiUrl:process.env.REACT_APP_HOST_API_KEY})
    const response = await fetch(`${process.env.REACT_APP_HOST_API_KEY}createPaymentIntent`,{method: 'POST',});
    var paymentIntentResponse = await response.json();
    // Render the form using the clientSecret
    clientSecret = paymentIntentResponse.client_secret
    // console.log({clientSecret});
   
  })();
  
  const options = {
    // passing the client secret obtained in step 3
    // clientSecret: 'pi_3MmGnjAp9ZiDeaxc1pdfLp5W_secret_gokoe5ibCJImtstpqc7o4yAxN',
    clientSecret: 'pi_3MmHv7Ap9ZiDeaxc0YxSQtAh_secret_olJsDJSWdipp7oNCJcMgBDsev',
    // clientSecret: paymentIntentResponse.client_secret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };


  
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  //   <ElementsConsumer>
  //   {({ stripe, elements }) => (
  //     <CheckoutForm stripe={stripePromise} elements={elements}  options={options} />
  //   )}
  // </ElementsConsumer>
  );
  //  PaymentService.createPaymentIntent().then((data) =>{
  //   console.log({data})
  //   clientSecret = data.client_secret
 
  // });

};

export default ECheckoutForm
// function App() {
//   const options = {
//     // passing the client secret obtained in step 3
//     clientSecret: '{{CLIENT_SECRET}}',
//     // Fully customizable with appearance API.
//     appearance: {/*...*/},
//   };

//   return (
//     <Elements stripe={stripePromise} options={options}>
//       <CheckoutForm />
//     </Elements>
//   );
// }; 

// ReactDOM.render(<App />, document.getElementById('root'));