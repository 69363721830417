import {
  DonnationPoster,
  QurbaniForm,
  SpecialRequestForm,
} from './components/DonationComponent';
import React, { useEffect, useState } from 'react';
import DeclineInfo from './components/DonationComponent/DeclineInfo';
import CreditCardForm from './components/CreditCardForm';
import DonationService from '../../services/donation.service';
import EmployeeMatchingGiftProgram from './components/EmployeeMatchingGiftProgram';
import ErrorFormat from '../../utils/ErrorFormatting';
import PersonalInfoForm from './components/PersonalInfoForm';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { wait } from '../../utils/constants';
import EcheckRadioButtons from '../../components/EcheckRadioButtons';
import ACHPaymentForm from './components/ACHPaymentForm';
import PlaidLink from '../../components/CreatePlaidLink';

const QurbaniDonation = () => {
  const navigate = useNavigate();
  const [specialRequest, setSpecialRequest] = useState([]);
  const [specialRequestBackup, setSpecialRequestBackup] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQurbaniAmount, setTotalQurbaniAmount] = useState(0);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [qurbaniDonation, setQurbaniDonation] = useState([]);
  const [totalDonation, setTotalDonation] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState('usd');
  const [isResetForm, setIsResetForm] = useState(false);
  const [employeeMatchingGiftProgram, setEmployeeMatchingGiftProgram] =
    useState({});

  const [isEcheck, setIsEcheck] = useState(false);
  const [achInfo, setAchInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [submissionState, setSubmissionState] = useState(0);

  const [stripeToken, setStripeToken] = useState(null);
  const handleStripeTokenReceived = (stripeToken) => {
    setStripeToken(stripeToken);
    console.log({ stripeToken });
  };

  //   const handleSum = (sum) => {
  //     setTotalAmount(sum);
  //   };

  const CalculateQurbaniAmount = () => {
    let totalSum = 0;

    for (let i = 0; i < qurbaniDonation.length; i++) {
      //   console.log(qurbaniDonation[i].total);
      totalSum += qurbaniDonation[i].total;
    }

    setTotalQurbaniAmount(totalSum);
  };

  //   console.log("qurbaniDonation", totalQurbaniAmount);

  useEffect(() => {
    CalculateQurbaniAmount();
  }, [qurbaniDonation]);

  const CalculateDonationAmount = () => {
    let totalSum = 0;

    for (let i = 0; i < totalDonation.length; i++) {
      // console.log(totalDonation[i].amount);
      totalSum += totalDonation[i].amount;
    }

    setTotalDonationAmount(totalSum);
  };

  useEffect(() => {
    CalculateDonationAmount();
  }, [totalDonation]);

  const SumUpTotalAmount = () => {
    setTotalAmount(totalDonationAmount + totalQurbaniAmount);
  };

  useEffect(() => {
    SumUpTotalAmount();
  }, [totalDonationAmount, totalQurbaniAmount]);

  const getDonationType = () => {
    DonationService.getSpecialDonationTypes().then((res) => {
      setSpecialRequest(res);
      setSpecialRequestBackup(res);
    });
  };

  // console.log("qurbani donation", finalDonationData);
  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const extendSubmissionState = () => {
    setSubmissionState((state) => state + 1);
  };

  const startSubmit = () => {
    setSubmissionState(1);
    setTimeout(() => {
      setSubmissionState(0);
    }, 1000);
  };
  useEffect(() => {
    // console.log(
    //   "OneTimeDonation | Validation submissionState",
    //   submissionState
    // );
    const totalForms = 1;
    if (submissionState >= totalForms + 1) {
      if (isEcheck) {
        if (stripeToken) {
          handleSubmit();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You did not connect a bank account!',
          });
        }
      } else {
        handleSubmit();
      }
    }
  }, [submissionState]);

  const checkIsQurbani = () => {
    {
      startSubmit();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (finalDonationData.qurbanies.length == 0) {
      setIsLoading(false);
      // alert("Please select at least one donation");
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select at least one field of Udhiya/Qurbani!',
      });
    } else {
      DonationService.submitDonation({
        matchingGift: employeeMatchingGiftProgram,
        personal: personalInfo,
        card: creditCardInfo,
        ach: { stripeToken },
        isEcheck,
        special: totalDonation,
        qurbanies: qurbaniDonation,
        donation_of: {
          oneTime: false,
          monthly: false,
          zakat_fitra_donation: false,
          qurbani: true,
          cause: false,
          special: totalDonation.length > 0,
        },
        payment_through: isEcheck ? 'ach' : 'card',
        currency: currency,
        is_special_request: '0',
        duration_id: 1,
      })
        .then((data) => {
          if (data.payment.status && data.email.status) {
            let errorExists;
            setIsLoading(false);
            if (!data.donor.status) errorExists = true;
            else if (!data.cc_details.status) errorExists = true;
            else errorExists = !data.qurbani.status;

            Swal.fire({
              title: 'Thank you for your donation!',
              text: `${
                errorExists
                  ? 'Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details'
                  : 'A confirmation email has been sent to the email provided.'
              }`,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((r) => window.location.reload());
          } else {
            setIsLoading(false);
            let errorMessages;
            if (!data.payment.status) errorMessages = data.payment.message;
            else if (!data.email.status) errorMessages = data.email.message;

            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(errorMessages),
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'Qurbani Donations | Ummah Relief';
    getDonationType();
    scrollTop();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      matchingGift: employeeMatchingGiftProgram,
      personal: personalInfo,
      card: creditCardInfo,
      ach: { stripeToken },
      isEcheck,
      special: totalDonation,
      qurbanies: qurbaniDonation,
      donation_of: {
        oneTime: false,
        monthly: false,
        zakat_fitra_donation: false,
        qurbani: true,
        cause: false,
        special: totalDonation.length > 0,
      },
      payment_through: isEcheck ? 'ach' : 'card',
      currency: currency,
      is_special_request: '0',
      duration_id: 1,
    });
  }, [
    qurbaniDonation,
    totalDonation,
    personalInfo,
    creditCardInfo,
    achInfo,
    currency,
    employeeMatchingGiftProgram,
  ]);

  useEffect(() => {
    // console.log(isValidated(personalInfo))
    if (
      isValidated(personalInfo) &&
      isValidated(creditCardInfo) &&
      isValidated(qurbaniDonation)
    ) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }, [qurbaniDonation]);

  const isValidated = (values) => {
    if (typeof values === 'object') {
      if (Array.isArray(values)) {
        // console.log("from array", values)
        return values.length > 0;
      } else {
        // console.log("from object", Object.values(values))
        return Object.values(values).every(
          (x) => x !== null || x !== '' || x !== 0
        );
      }
    } else {
      return values !== '' || values !== null;
    }
  };

  const resetForm = async () => {
    setIsResetForm(true);
    setTotalAmount(0);
    setTotalDonation([]);
    setQurbaniDonation([]);
    setPersonalInfo({});
    setCreditCardInfo({});
    setFinalDonationData({});
    setAchInfo({});
    setIsEcheck(false);
    setSpecialRequest(specialRequestBackup);
    await wait(1);
    setIsResetForm(false);
  };

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-7 col-md-12">
        {!isResetForm && (
          <SpecialRequestForm
            options={specialRequest}
            setOptions={setSpecialRequest}
            //   handleSum={(sum) => setTotalAmount(sum)}
            setTotalDonation={(total) => setTotalDonation(total)}
            totalDonation={totalDonation}
            totalAmount={totalAmount}
          />
        )}
        {!isResetForm && (
          <QurbaniForm
            qurbaniDonation={qurbaniDonation}
            //   setTotalAmount={(sum) => setTotalAmount(sum)}
            totalAmount={totalAmount}
            setQurbaniDonation={(donation) => setQurbaniDonation(donation)}
          />
        )}
        <h4 className="fw-bold">Total: ${totalAmount}</h4>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0">
        {!isResetForm && (
          <PersonalInfoForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            personalInfo={personalInfo}
            setPersonalInfo={(info) => setPersonalInfo(info)}
          />
        )}
        {!isResetForm && (
          <EmployeeMatchingGiftProgram
            setEmployeeMatchingGiftProgram={(info) =>
              setEmployeeMatchingGiftProgram(info)
            }
          />
        )}
        <EcheckRadioButtons
          isEcheck={isEcheck}
          setIsEcheck={(echeck) => setIsEcheck(echeck)}
        />
        {!isResetForm && !isEcheck && (
          <CreditCardForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            creditCardInfo={creditCardInfo}
            setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
          />
        )}
        {!isResetForm && isEcheck && (
          <PlaidLink onStripeTokenReceived={handleStripeTokenReceived} />
          // <ACHPaymentForm
          //   submissionState={submissionState}
          //   extendSubmissionState={extendSubmissionState}
          //   achInfo={achInfo}
          //   setAchInfo={(ach) => setAchInfo(ach)}
          //   errors={errors}
          // />
        )}
        <div className="d-flex justify-content-start flex-wrap align-items-center gap-3">
          <button
            className="text-uppercase btn-green border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            disabled={isLoading}
            onClick={checkIsQurbani}
            type="button"
          >
            {isLoading ? (
              <>
                Proccessing{' '}
                <span
                  className="spinner-border"
                  role="status"
                  style={{
                    height: '14px',
                    width: '14px',
                  }}
                ></span>
              </>
            ) : (
              'Submit'
            )}
          </button>
          <button
            onClick={resetForm}
            className="text-uppercase btn-orang border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            type="button"
            disabled={isLoading}
          >
            Reset
          </button>
          <button
            type="button"
            className="text-uppercase btn-gray text-white border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            onClick={() =>
              navigate(`/`, {
                replace: true,
              })
            }
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
        {/* <div className='d-flex justify-content-end gap-3'>
          <button
            className='text-uppercase btn btn-outline-light text-white bg-success text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg '
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '12px',
            }}
            disabled={isLoading}
            onClick={startSubmit}
            type='button'
          >
            {isLoading ? 'Processing...' : 'Donate Now'}
          </button>
        </div> */}
        <DeclineInfo type={'Qurbani'} />
        <DonnationPoster />
      </div>
    </div>
  );
};

export default QurbaniDonation;
