import React, { useEffect, useState } from "react";

import { CauseDetailBody } from "./components/CauseDetailComponent";
import CausesService from "../../services/causes.service";
import { PageHeader } from "../../components";
import { useParams } from "react-router-dom";

const CauseDetail = () => {
  const params = useParams();
  const [cause, setCause] = useState({});

  const getCauseDetails = () => {
    CausesService.getSingleCause(params.causeID).then((res) => {
      // console.log(res)
      setCause(res);
    });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Cause Details | Ummah Relief";
    getCauseDetails();
    scrollTop();
  }, [params]);

  const breadcrumbItems = [
    {
      title: "Home",
      route: "/",
      isLast: false,
    },
    {
      title: "Causes",
      route: `/causes/1`,
      isLast: false,
    },
    {
      title: cause.title,
      route: `/causes/details/${cause.id}`,
      isLast: true,
    },
  ];
  return (
    <>
      <PageHeader
        breadcrumbItems={breadcrumbItems}
        title={cause.title ? cause.title : "Loading..."}
      />
      <CauseDetailBody cause={cause} />
    </>
  );
};

export default CauseDetail;
