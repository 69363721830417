import React, { useEffect } from 'react';

import { MaskedInput } from '../../../../components';

const tdAmountWidth = '100px';
const DonationForm = ({
  title,
  donationOption,
  setDonationOption = () => { },
  handleSum,
  totalAmount,
  setTotalDonation,
  totalDonation,
}) => {
  const handleRadioClick = (id, title, value) => {
    const dataObj = {
      donation_type_id: id,
      title: title,
      amount: value,
      is_special_request: 0,
    };
    var temp = [...donationOption];
    temp.map((item, index) => {
      if (item.id === id) {
        temp[index] = { ...item, amount: '' };
      }
    });
    setDonationOption([...temp]);
    totalDonation.forEach((item, index) => {
      if (totalDonation && item.donation_type_id === dataObj.donation_type_id) {
        totalDonation.splice(index, 1);
      }
    });
    setTotalDonation((old) => [...old, dataObj]);
  };

  const handInputChange = (id, value, title) => {
    const dataObj = {
      donation_type_id: id,
      title: title,
      amount: value ? parseInt(value) : 0,
      is_special_request: 0,
    };

    var temp = [...donationOption];
    temp.map((item, index) => {
      if (item.id === id) {
        temp[index] = { ...item, amount: parseInt(value) };
      }
    });
    setDonationOption([...temp]);

    if (parseInt(dataObj.amount) < 1) {
      totalDonation.forEach((item, index) => {
        if (
          totalDonation &&
          item.donation_type_id === dataObj.donation_type_id
        ) {
          totalDonation.splice(index, 1);
        }
      });
      setTotalDonation([...totalDonation]);
    } else {
      totalDonation.forEach((item, index) => {
        if (
          totalDonation &&
          item.donation_type_id === dataObj.donation_type_id
        ) {
          totalDonation.splice(index, 1);
        }
      });
      setTotalDonation((old) => [...old, dataObj]);
    }
  };

  function handleInputFocus(e, id) {
    const option1 = document.getElementById(`option_1_${id}`);
    const option2 = document.getElementById(`option_2_${id}`);
    const option3 = document.getElementById(`option_3_${id}`);
    const option4 = document.getElementById(`option_4_${id}`);
    const option5 = document.getElementById(`option_5_${id}`);
    totalDonation.forEach((item, index) => {
      if (totalDonation && item.donation_type_id === id) {
        totalDonation.splice(index, 1);
      }
    });
    option1.checked = false;
    option2.checked = false;
    option3.checked = false;
    option4.checked = false;
    //option5.value = '';
  }

  const getSum = () => {
    return totalDonation.reduce(function (sum, current) {
      return sum + parseInt(current.amount ? current.amount : 0);
    }, 0);
  };

  useEffect(() => {
    if (handleSum !== undefined) {
      handleSum(getSum());
    }
  }, [totalDonation]);

  return (
    <div className='mt-3'>
      <h3 className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row w-full justify-content-between align-items-center gap-1 pb-2 mb-2 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0 fw-bold'>
        {title}
      </h3>
      {donationOption &&
        donationOption.map((item, index) => (
          <div
            className='d-flex flex-column flex-sm-column flex-md-column flex-lg-column  flex-xl-row w-full justify-content-between align-items-center gap-0 border-bottom'
            key={index}
          >
            <div className='donationTitle pt-2'>
              <strong>{item.title}</strong>
            </div>
            <div
              className='d-flex flex-column flex-md-row flex-lg-row donationOptions
             justify-content-start align-items-center gap-0 w-100 p-2'
            >
              <table className='table p-0 m-0 w-100'>
                <tbody>
                  <tr>
                    <td className='border-0 p-2' style={{ widows: tdAmountWidth }}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name={item.id}
                          id={`option_1_${item.id}`}
                          defaultChecked={false}
                          onChange={() =>
                            handleRadioClick(item.id, item.title, item.option1)
                          }
                        />
                        <label
                          className='form-check-label text-dark pe-1'
                          htmlFor={`option_1_${item.id}`}
                        >
                          <span className=''>$</span>
                          {item.option1}
                        </label>
                      </div>
                    </td>
                    <td className='border-0 p-2 ' style={{ widows: tdAmountWidth }}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name={item.id}
                          id={`option_2_${item.id}`}
                          defaultChecked={false}
                          onChange={(e) =>
                            handleRadioClick(item.id, item.title, item.option2)
                          }
                        />
                        <label
                          className='form-check-label text-dark pe-1'
                          htmlFor={`option_2_${item.id}`}
                        >
                          <span className=''>$</span>
                          {item.option2}
                        </label>
                      </div>
                    </td>
                    <td className='border-0 p-2 ' style={{ widows: tdAmountWidth }}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name={item.id}
                          id={`option_3_${item.id}`}
                          defaultChecked={false}
                          onChange={(e) =>
                            handleRadioClick(item.id, item.title, item.option3)
                          }
                        />
                        <label
                          className='form-check-label text-dark pe-1'
                          htmlFor={`option_3_${item.id}`}
                        >
                          <span className=''>$</span>
                          {item.option3}
                        </label>
                      </div>
                    </td>
                    <td className='border-0 p-2 ' style={{ widows: tdAmountWidth }}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name={item.id}
                          id={`option_4_${item.id}`}
                          defaultChecked={false}
                          onChange={(e) =>
                            handleRadioClick(item.id, item.title, item.option4)
                          }
                        />
                        <label
                          className='form-check-label text-dark pe-1'
                          htmlFor={`option_4_${item.id}`}
                        >
                          <span className=''>$</span>
                          {item.option4}
                        </label>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>
              {/* <div className='form-group d-flex justify-content-end align-items-center'> */}
              <div className="form-check pt-0" style={{ width: tdAmountWidth }}>
                <input
                  className='form-check-input'
                  style={{ marginRight: '4px', marginTop: '10px' }}
                  type='radio'
                  name={item.id}
                  id={`option_5_${item.id}`}
                  defaultChecked={false}
                  onChange={(e) =>
                    handleRadioClick(item.id, item.title, item.option4)
                  }
                />
                {/* <label
                  htmlFor='other_amount'
                  className='d-flex text-dark pe-1 w-100'
                >
                  Other $
                </label> */}
                <MaskedInput
                  id={`option_5_${item.id}`}
                  placeholder='Other'
                  mask='999999'
                  name={item.id}
                  value={item.amount}
                  min={0}
                  max={19}
                  className=''
                  style={{
                    width: tdAmountWidth,
                    // textAlign: 'left',
                    // borderRadius: '28px !important',
                  }}
                  onBlur={(e) => {
                    e.target.value !== ''
                      ? handInputChange(item.id, e.target.value, item.title)
                      : handInputChange(item.id, item.amount, item.title);
                  }}
                  onFocus={(e) => {
                    document.getElementById(`option_5_${item.id}`).checked = true;

                    handleInputFocus(e, item.id)
                  }}
                  onChange={(e) =>
                    handInputChange(item.id, e.target.value, item.title)
                  }

                />
                {/* <label
                  htmlFor={`option_5_${item.id}`}
                  className="form-label text-dark pt-2 mx-3"
                  style={{ width: "50%", textAlign: "left" }}
                >
                  Other $
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0"
                  name={item.id}
                  id={`option_5_${item.id}`}
                  min="0"
                  onFocus={(e) => handleInputFocus(e, item.id)}
                  onChange={(e) => handInputChange(item.id, e, item.title)}
                  style={{ width: "70%", textAlign: "right" }}
                /> */}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DonationForm;
