import React, { useEffect, useState } from 'react';
import { nameRegex, zipCodeRegex } from '../../../utils/constants';

import FormValidator from '../../../utils/validation';
import LocationService from '../../../services/location.service';
import { MaskedInput } from '../../../components';
import PhoneInputWrapper from '../../../components/PhoneInputWrapper';
import Select from 'react-select';

//

//

const PersonalInfoForm = ({
  personalInfo,
  setPersonalInfo,
  submissionState,
  extendSubmissionState,
}) => {
  // Select2 dropdown values
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [disableState, setDisableState] = useState(false);
  const [cities, setCities] = useState([]);
  const [disableCity, setDisableCity] = useState(false);
  const [hearAboutUsList] = useState([
    {
      label: 'Google',
      value: 'Google',
    },
    {
      label: 'Mailer/Flyer',
      value: 'Mailer/Flyer',
    },
    {
      label: 'TV Commercial',
      value: 'TV Commercial',
    },
  ]);

  const [errors, setErrors] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [countryID, setCountryID] = useState('');
  const [stateID, setStateID] = useState(0);
  const [cityName, setCityName] = useState('');
  const [cityID, setCityID] = useState(0);
  const [zipcode, setZipcode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [hearAboutUs, setHearAboutUs] = useState('');
  const [stateErrorMsg, setStateErrorMsg] = useState('');
  const [cityErrorMsg, setCityErrorMsg] = useState('');

  const validateMe = () => {
    const fn = FormValidator.handleOnBlur(
      { target: { id: 'first_name', value: firstName } },
      setErrors
    );
    const ln = FormValidator.handleOnBlur(
      { target: { id: 'last_name', value: lastName } },
      setErrors
    );
    const ad = FormValidator.handleOnBlur(
      { target: { id: 'address', value: address } },
      setErrors
    );
    const cn = FormValidator.handleOnBlur(
      { target: { id: 'country', value: countryID } },
      setErrors
    );
    const st =
      stateErrorMsg == ''
        ? FormValidator.handleOnBlur(
            { target: { id: 'state', value: stateID } },
            setErrors
          )
        : true;

    const ct =
      stateErrorMsg == '' && cityErrorMsg == ''
        ? FormValidator.handleOnBlur(
            { target: { id: 'city', value: cityName } },
            setErrors
          )
        : true;
    const zc = FormValidator.handleOnBlur(
      { target: { id: 'zip_code', value: zipcode } },
      setErrors
    );
    const em = FormValidator.handleOnBlur(
      { target: { id: 'email', value: email } },
      setErrors
    );
    const ph = FormValidator.handleOnBlur(
      { target: { id: 'phone', value: phone } },
      setErrors
    );

    var hau = true;
    if (hearAboutUs === '') {
      setErrors({ ...errors, hearAboutUs: 'Field is required' });
      hau = false;
    }

    return fn && ln && ad && cn && st && ct && zc && em && ph && hau;
  };

  useEffect(() => {
    if (submissionState === 1) {
      if (validateMe()) {
        extendSubmissionState();
      } else {
        // console.log('PersonalInfoForm | Validation failed');
      }
    }
  }, [submissionState]);

  const countryValidator = () => {
    if (countryID === 0) {
      setErrors((old) => {
        return { ...old, country: 'country is required' };
      });
    } else {
      setErrors((old) => {
        return { ...old, country: '' };
      });
    }
  };

  const stateValidator = () => {
    if (stateID === 0) {
      setErrors((old) => {
        return { ...old, state: 'state is required' };
      });
    } else {
      setErrors((old) => {
        return { ...old, state: '' };
      });
    }
  };

  const cityValidator = () => {
    if (cityName && cityName === '') {
      setErrors((old) => {
        return { ...old, city: 'city is required' };
      });
    } else {
      setErrors((old) => {
        return { ...old, city: '' };
      });
    }
  };

  const getCountries = () => {
    LocationService.getCountries().then((res) => {
      const countries = Object.entries(res).map(([name, country]) => {
        if (country.name.toLocaleLowerCase() === 'united states') {
          setCountryID(country.id);
        }
        return {
          value: country.id,
          label: country.name,
        };
      });
      // setCountryID(countries[0]);

      setCountries(countries);
    });
  };

  const getStates = (e) => {
    if (e && e !== '') {
      setDisableState(false);
      LocationService.getStates(e).then((res) => {
        setStateErrorMsg('');
        if (res && res.length > 0) {
          const states = Object.entries(res).map(([name, state]) => {
            return {
              value: state.id,
              label: state.name,
            };
          });
          setStates(states);
        } else {
          var cntry = '';
          countries.map((item, i) => {
            // console.log('item.value: ', item.value);
            if (e == item.value) {
              cntry = item.label;
            }
          });
          setStateErrorMsg(`No State listed related to  ${cntry}`);
        }
      });
    } else {
      setDisableState(true);
      // setErrors((old) => {
      // return { ...old, country: "Field is required" };
      // });
      setStates([]);
      //setStateID('');
    }
  };

  const getCities = (e) => {
    setStateID(e.target.value);
    if (e && e.target.value !== '') {
      LocationService.getCities(countryID, e.target.value).then((res) => {
        // console.log("res", res);
        if (res.length > 0) {
          const cities = Object.entries(res).map(([name, city]) => {
            return {
              value: city.id,
              label: city.name,
            };
          });
          setCities(cities);
          setCityErrorMsg('');
        } else {
          var cntry = '';

          states.map((item, i) => {
            // console.log('item.value: ', item.value);
            if (e.target.value == item.value) {
              cntry = item.label;
            }
          });
          setCityErrorMsg(`No City listed related to  ${cntry}`);
        }
      });
    } else {
      /* setCityID(0);
      setCities([]); */
      // setErrors((old) => {
      //   return { ...old, state: "Field is required" };
      // });
    }
  };

  const handleCityChange = (e) => {
    //setCityID(e.value);
    //  else {
    //   setErrors((old) => {
    //     return { ...old, city: "Field is required" };
    //   });
    // }
  };

  const handleHearAboutChange = (e) => {
    if (e && e.value !== 0) {
      setHearAboutUs(e.value);
      setErrors((old) => {
        return { ...old, hearAboutUs: '' };
      });
    } else {
      setErrors((old) => {
        return { ...old, hearAboutUs: 'Field is required' };
      });
    }
  };

  useEffect(() => {
    getCountries();
    setDisableState(true);
  }, []);
  useEffect(() => {
    if (countryID && countryID != '') {
      getStates(countryID);
      setDisableState(false);
    }
  }, [countryID]);

  useEffect(() => {
    const obj = {
      first_name: firstName,
      last_name: lastName,
      address: address,
      country_id: countryID,
      state_id: stateID,
      //city_id: cityID,
      city_name: cityName,
      zip_code: zipcode,
      phone: phone,
      email: email,
      hear_about_us: hearAboutUs,
    };
    if (setPersonalInfo !== undefined) {
      setPersonalInfo({ ...obj });
    }
  }, [
    firstName,
    lastName,
    address,
    countryID,
    stateID,
    cityName,
    zipcode,
    phone,
    email,
    hearAboutUs,
  ]);

  return (
    <>
      <h4 className='fw-bold'>Personal Info</h4>
      <hr />
      {/*<MaskedInput label="First Name" id="first_name" mask="9999-9999-9999-9999" onChange={(e) => setFirstName(e.target.value)} value={firstName}/>*/}
      <div className='form-group mb-3'>
        <label htmlFor='first_name' className='form-label text-dark'>
          First Name <span className='text-danger'>*</span>
        </label>

        <input
          type='text'
          name='first_name'
          id='first_name'
          onChange={(e) => {
            nameRegex.test(e.target.value) &&
              setFirstName(e.target.value.replace(/^\d+$/, ''));
          }}
          value={firstName}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='First Name *'
          required={true}
        />
        {errors.firstName !== '' && (
          <small className='text-danger'>{errors.firstName}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='last_name' className='form-label text-dark'>
          Last Name <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='last_name'
          id='last_name'
          onChange={(e) => {
            nameRegex.test(e.target.value) &&
              setLastName(e.target.value.replace(/^\d+$/, ''));
          }}
          value={lastName}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='Last Name *'
          required={true}
        />
        {errors.lastName !== '' && (
          <small className='text-danger'>{errors.lastName}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='address' className='form-label text-dark'>
          Address
          <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='address'
          id='address'
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='Address (Street name and Unit #) *'
          required={true}
        />
        {errors.address !== '' && (
          <small className='text-danger'>{errors.address}</small>
        )}
      </div>

      <div className='form-group mb-3'>
        <label htmlFor='city_id' className='form-label text-dark'>
          City
          <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='city'
          id='city'
          onChange={(e) => {
            setCityName(e.target.value);
          }}
          value={cityName}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='City *'
          required={true}
        />
        {/* <select
          className='form-select'
          aria-label='Default select example'
          required={true}
          name='city'
          id='city'
          onChange={(e) => setCityID(e.target.value)}
          value={cityID}
          disabled={disableCity}
          onBlur={(e) => {
            cityErrorMsg == '' && FormValidator.handleOnBlur(e, setErrors);
          }}
        >
          <option value={0}>Select a City</option>
          {cities &&
            cities.length > 0 &&
            cities.map((city, index) => (
              <option value={city.value} key={index}>
                {city.label}
              </option>
            ))}
        </select> */}
        {/* <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={cities[0]}
          isLoading={false}
          isDisabled={disableCity}
          onBlur={(e) => cityValidator(e)}
          isClearable={false}
          isSearchable={true}
          onChange={(e) => handleCityChange(e)}
          name="city"
          id="city"
          options={cities}
        /> */}
        {cityErrorMsg !== '' && (
          <div>
            <small className='text-muted'>{cityErrorMsg}</small>
            <br />
          </div>
        )}
        {errors.city && errors.city !== '' && (
          <small className='text-danger'>{errors.city}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='state' className='form-label text-dark'>
          State
          <span className='text-danger'>*</span>
        </label>
        <select
          className='form-select'
          aria-label='Default select example'
          required={true}
          name='state'
          id='state'
          onChange={(e) => {
            setStateID(e.target.value);
            /* getCities(e);
            setCities([]);
            setCityID(0); */
          }}
          value={stateID}
          disabled={disableState}
          onBlur={(e) => {
            stateErrorMsg == '' && FormValidator.handleOnBlur(e, setErrors);
          }}
        >
          <option value={0}>Select a State</option>
          {states &&
            states.length > 0 &&
            states.map((state, index) => (
              <option value={state.value} key={index}>
                {state.label}
              </option>
            ))}
        </select>
        {/* <Select
          className="basic-single"
          classNamePrefix="select"
          // defaultValue={states[0]}
          isLoading={false}
          isDisabled={disableState}
          isClearable={false}
          isSearchable={true}
          onChange={(e) => getCities(e)}
          onBlur={(e) => stateValidator(e)}
          // onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          // value={stateID !== "" && stateID}
          id="state"
          name="state"
          options={states}
          required={true}
        />
        {errors.state && errors.state !== "" && (
          <small className="text-danger">{errors.state}</small>
        )} */}
        {stateErrorMsg !== '' && (
          <div>
            <small className='text-muted'>{stateErrorMsg}</small>
          </div>
        )}
        {errors.state !== '' && (
          <small className='text-danger'>{errors.state}</small>
        )}
      </div>

      <div className='form-group mb-3'>
        {/* <MaskedInput
          label="Zip Code"
          id="zip_code"
          placeholder="0"
          mask="123456"
          name="zip_code"
          min={0}
          max={6}
          onBlur={(e) => {
            FormValidator.handleOnBlur(e, setErrors);
          }}
          onChange={(e) => setZipcode(e.target.value)}
          required={true}
          style={{
            width: "100%",
            textAlign: "right",
            borderRadius: "28px !important",
          }}
        /> */}
        <label htmlFor='zip_code' className='form-label text-dark'>
          Zipcode
          <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='zip_code'
          id='zip_code'
          onChange={(e) => {
            zipCodeRegex.test(e.target.value) && setZipcode(e.target.value);
          }}
          value={zipcode}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='Zipcode *'
          required={true}
        />
        {errors.zipCode !== '' && (
          <small className='text-danger'>{errors.zipCode}</small>
        )}
      </div>

      <div className='form-group mb-3'>
        <label htmlFor='country' className='form-label text-dark'>
          Country <span className='text-danger'>*</span>
        </label>
        <select
          className='form-select'
          aria-label='Default select example'
          required={true}
          name='country'
          id='country'
          onChange={(e) => {
            setCountryID(e.target.value);
            getStates(e.target.value);
            setStates([]);
            //setCities([]);
            setStateID(0);
            //setCityID(0);
          }}
          value={countryID}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
        >
          <option value=''>Select a Country</option>
          {countries &&
            countries.length > 0 &&
            countries.map((country, index) => (
              <option value={country.value} key={index}>
                {country.label}
              </option>
            ))}
        </select>
        {errors.country && errors.country !== '' && (
          <small className='text-danger'>{errors.country}</small>
        )}
      </div>
      {/* <div className="form-group mb-3">
        <label htmlFor="country_id" className="form-label text-dark">
          Country <span className="text-danger">*</span>
        </label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          // defaultValue={countries[0]}
          onBlur={(e) => countryValidator()}
          isLoading={false}
          isClearable={false}
          isSearchable={true}
          onChange={(e) => {
            getStates(e);
          }}
          // autoFocus={(e)=>countryID===0}
          // openMenuOnFocus={true}
          // menuIsOpen
          id="country"
          name="country"
          options={countries}
          // value={countryID !== "" && countryID}
          isRequired={true}
        />
        {errors.country && errors.country !== "" && (
          <small className="text-danger">{errors.country}</small>
        )}
      </div> */}

      <div className='form-group mb-3'>
        <label htmlFor='email' className='form-label text-dark'>
          Email <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='email'
          id='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          className='form-control'
          placeholder='Email *'
          required={true}
        />
        {errors.email !== '' && (
          <small className='text-danger'>{errors.email}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        {/*  <label htmlFor='phone' className='form-label text-dark'>
          Phone
          <span className='text-danger'>*</span>
        </label>
        <PhoneInputWrapper
          style={{
            width: '100%',
            borderRadius: '28px !important',
          }}
          country='us'
          inputExtraProps={{
            name: 'phone',
            required: true,
            autoFocus: false,
          }}
          inputProps={{ id: 'phone' }}
          inputStyle={{ width: '-webkit-fill-available' }}
          name='phone'
          id='phone'
          onChange={(value) => setPhone(value)}
          onBlur={(e) => {
            FormValidator.handleOnBlur(e, setErrors);
          }}
          value={phone}
        /> */}
        <MaskedInput
          label='Phone'
          id='phone'
          placeholder='0'
          mask='+99999999999'
          name='phone'
          min={5}
          max={19}
          onBlur={(e) => {
            FormValidator.handleOnBlur(e, setErrors);
          }}
          required={true}
          onChange={(e) => setPhone(e.target.value)}
          style={{
            width: '100%',
            textAlign: 'right',
            borderRadius: '28px !important',
          }}
        />

        {errors.phone !== '' && (
          <small className='text-danger'>{errors.phone}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='hear_about_us' className='form-label text-dark'>
          How did you hear about us: <span className='text-danger'>*</span>
        </label>
        <Select
          className='basic-single'
          classNamePrefix='select'
          isLoading={false}
          isClearable={true}
          isSearchable={false}
          onChange={(e) => handleHearAboutChange(e)}
          name='hear_about_us'
          options={hearAboutUsList}
          id='hear_about_us'
        />
        {errors.hearAboutUs !== '' && (
          <small className='text-danger'>{errors.hearAboutUs}</small>
        )}
      </div>
    </>
  );
};

export default PersonalInfoForm;
