import React, { useEffect, useState } from 'react';
// import Stripe from 'stripe';

// const stripe = new Stripe('YOUR_STRIPE_SECRET_KEY');
import {loadStripe} from '@stripe/stripe-js';
import FormValidator from '../../../utils/validation';
import { nameRegex } from '../../../utils/constants';
const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const ACHPaymentForm = ({
  achInfo,
  setAchInfo,
  submissionState,
  extendSubmissionState,
}) => {
  const [customer, setCustomer] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});
  const [paymentIntent, setPaymentIntent] = useState({});

  const [paymentType] = useState('stripe');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const [errors, setErrors] = useState({});

  
  const validateMe = () => {
    const ahn = FormValidator.handleOnBlur(
      { target: { id: 'account_holder_name', value: accountHolderName } },
      setErrors
    );
    const rn = FormValidator.handleOnBlur(
      { target: { id: 'routing_number', value: routingNumber } },
      setErrors
    );
    const an = FormValidator.handleOnBlur(
      { target: { id: 'account_number', value: accountNumber } },
      setErrors
    );
  //  console.log({ahn,rn,an})
    return ahn && rn && an  === true;
  };
  useEffect(() => {
    // console.log({submissionState})
    if (submissionState === 1) {
      if (validateMe()) {
        extendSubmissionState();
      } else {
        // console.log('AchForm | Validation failed');
      }
    }
  }, [submissionState]);
 
  useEffect(() => {
    const obj = {
      account_holder_name: accountHolderName,
      routing_number: routingNumber,
      account_number: accountNumber,
    
    };

    if (setAchInfo !== undefined) {
      setAchInfo({ ...obj });
    }
  }, [paymentType, accountHolderName, routingNumber, accountNumber]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const { firstName, lastName, email, routingNumber, accountNumber } = event.target.elements;

    // Create a Stripe customer object
    const { id: customerId } = await stripe.customers.create({
      name: `${firstName.value} ${lastName.value}`,
      email: email.value,
    });
    setCustomer({ id: customerId });

    // Create a PaymentMethod object
    const { id: paymentMethodId } = await stripe.paymentMethods.create({
      type: 'ach_credit_transfer',
      ach_credit_transfer: {
        account_number: accountNumber.value,
        routing_number: routingNumber.value,
      },
      customer: customerId,
    });
    setPaymentMethod({ id: paymentMethodId });

    // Create a PaymentIntent object
    const { id: paymentIntentId } = await stripe.paymentIntents.create({
      amount: 1000, // amount in cents
      currency: 'usd',
      payment_method_types: ['ach_credit_transfer'],
      payment_method: paymentMethodId,
      customer: customerId,
    });
    setPaymentIntent({ id: paymentIntentId });

    // Confirm the PaymentIntent
    await stripe.paymentIntents.confirm(paymentIntentId);
  };

  return (
    <>
     <h4 className='mt-4 fw-bold pt-5 pt-lg-0 pt-xl-0 pt-xxl-0'>
        Bank Information
      </h4>
      <hr />
    <form onSubmit={handleFormSubmit}>
    <div className='form-group mb-3'>
        <label htmlFor='account_holder_name' className='form-label text-dark'>
          Account Holder Name <span className='text-danger'>*</span>
        </label>
        <input
          type='text'
          name='account_holder_name '
          onChange={(e) => {
            nameRegex.test(e.target.value) &&
              setAccountHolderName(e.target.value.replace(/^\d+$/, ''));
          }}
          // onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          id='account_holder_name'
          className='form-control'
          // value={nameOnCard}
          placeholder='Account Holder Name *'
          required={true}
          value={accountHolderName}
        />
        {/* {errors.cardName !== '' && (
          <small className='text-danger'>{errors.cardName}</small>
        )} */}
      </div>
    <div className='form-group mb-3'>
        <label htmlFor='routing_number' className='form-label text-dark'>
          Routing Number <span className='text-danger'>*</span>
        </label>
        <input
          type='number'
          name='routing_number '
          onChange={(e) => {
            FormValidator.isValidRoutingNumber(e.target.value)
              setRoutingNumber(e.target.value);
          }}
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          id='routing_number'
          className='form-control'
          // value={nameOnCard}
          placeholder='Routing Number *'
          required={true}
          value={routingNumber}
        />
        {/* {errors.cardName !== '' && (
          <small className='text-danger'>{errors.cardName}</small>
        )} */}
      </div>
    <div className='form-group mb-3'>
        <label htmlFor='account_number' className='form-label text-dark'>
          Account Number <span className='text-danger'>*</span>
        </label>
        <input
          type='number'
          name='account_number '
          onChange={(e) => {
            FormValidator.isValidAccountNumber(e.target.value)
              setAccountNumber(e.target.value);
          }}
          // onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          id='account_number'
          className='form-control'
          // value={nameOnCard}
          placeholder='Account Number *'
          required={true}
          value={accountNumber}
        />
        {/* {errors.cardName !== '' && (
          <small className='text-danger'>{errors.cardName}</small>
        )} */}
      </div>
     
    </form>
    </>
  );
};

export default ACHPaymentForm;
