import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Backdrop, Box, Modal } from '@mui/material';
import 'keen-slider/keen-slider.min.css';

const MediaSliderCause = ({ media = [] }) => {
  const blogImagesAndVideosArray = media || [];

  const [open, setOpen] = React.useState(false);
  const [modalData, setModatData] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="d-flex gap-4 align-items-center flex-wrap justify-content-center">
        {blogImagesAndVideosArray.map((item, index) => {
          if (item.type === 'image') {
            return (
              <div
                key={index}
                className="keen-slider__slide number-slide3 flex-1"
                onClick={() => {
                  setModatData({ index });
                  handleOpen();
                }}
                style={{
                  height: '200px',
                  cursor: 'pointer',
                  minWidth: 'max-content',
                }}
              >
                <img
                  style={{
                    height: '200px',
                    cursor: 'pointer',
                  }}
                  src={item?.thumbnail}
                />
              </div>
            );
          }
          if (item.type === 'video') {
            return (
              <div
                key={index}
                className="keen-slider__slide number-slide3"
                style={{ minWidth: 'max-content' }}
                onClick={() => {
                  setModatData({ index });
                  handleOpen();
                }}
              >
                <img
                  style={{
                    height: '200px',
                    cursor: 'pointer',
                  }}
                  src={item?.thumbnail}
                />
                {/* <video
                           
                            style={{
                              height: '200px',
                              cursor: 'pointer',
                            }}
                            className="video-player w-100"
                            controls={false}
                          >
                            <source src={item.src} type="video/mp4" />
                            <source src={item.src} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video> */}
              </div>
            );
          }
          return null;
        })}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {}}
        slots={{ backdrop: Backdrop }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            bgcolor: 'none',
            boxSizing: 'unset',
            height: '100vh',
            p: 4,
          }}
        >
          <div className="h-100">
            <div className="w-100 h-100">
              <div className="d-flex justify-content-end px-3">
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '20px',
                  }}
                  onClick={handleClose}
                >
                  <i className="fas fa-close" />
                </div>
              </div>
              <div className="h-100 d-flex align-items-center">
                <div className="w-100">
                  <Carousel
                    index={modalData?.index}
                    autoPlay={true}
                    stopAutoPlayOnHover={true}
                    indicators={true}
                    fullHeightHover={true}
                    animation={'slide'}
                    navButtonsAlwaysVisible={true}
                    changeOnFirstRender={true}
                  >
                    {Array.isArray(blogImagesAndVideosArray) &&
                      blogImagesAndVideosArray.map((item, i) => (
                        <Slide index={i} item={item} />
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MediaSliderCause;

const Slide = ({ item = {}, index = 0, height = 600 }) => {
  if (item.type === 'image') {
    return (
      <div className="d-flex justify-content-center px-5 zoom-img blog-carosul-content-height">
        <img className="px-5" key={index} src={item.src} />
      </div>
    );
  }
  return (
    <div className="px-5 blog-carosul-content-height">
      <video
        key={index}
        className="video-player w-100 px-5 h-100"
        autoPlay={true}
        controls
      >
        <source src={item.src} type="video/mp4" />
        <source src={item.src} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
