import { DonnationPosterImg } from '../../../../utils/Images';
import React from 'react';

const DonnationPoster = () => {
  return (
    <>
      <div className='w-100 py-3 pb-0 '>
        <div className='w-100 d-flex'>
          <img
            src={DonnationPosterImg}
            style={{ height: '190px', width: '250px' }}
            className=' mx-auto'
            alt='Ummah Relief Donation'
            id='zelle-poster-logo'
          />
        </div>

        <p className='text-dark' style={{ fontSize: '100%' }}>
          Ummah Relief International is a U.S. based non-profit organization
          established in 1994. Tax ID# 36-3954960, Not for Profit 501(c)3. All
          your donations are tax deductible.
        </p>
        <p className='text-muted' style={{ fontSize: '105%' }}>
          * Ummah Relief International may use its discretion to redirect
          donations in an emergency to where the need is greatest
        </p>
      </div>
    </>
  );
};

export default DonnationPoster;
