import API from '../utils/api';

const getCauses = (pageNo) => {
  return API.get(`/causes?page=${pageNo}`).then((response) => {
    return response.data;
  });
};

const getSingleCause = (causeID) => {
  return API.get(`/causes/${causeID}`).then((response) => {
    return response.data;
  });
};

const getCausesForHome = () => {
  return API.get('causesForCarousel').then((response) => {
    return response.data;
  });
};

const getCarousel = () => {
  return API.get('carousel').then((response) => {
    return response.data;
  });
};

const getCausesByQuery = (searchType, data) => {
  return API.post(`getCausesByQuery/${searchType}`, data).then((response) => {
    return response.data;
  });
};
const getDonationByCauseID = (causeID) => {
  return API.get(`getDonationByCauseID/${causeID}`).then((response) => {
    return response.data;
  });
};

const CauseService = {
  getCauses,
  getSingleCause,
  getCausesForHome,
  getCausesByQuery,
  getDonationByCauseID,
  getCarousel,
};

export default CauseService;
