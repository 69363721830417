import React, { useEffect, useState } from 'react';

const EcheckRadioButtons = ({ isEcheck, setIsEcheck }) => {
  const [echeck, setEcheck] = useState(false);
  useEffect(() => {
    if (setIsEcheck !== undefined) {
      setIsEcheck(echeck);
    }
  }, [echeck]);
  return (
    <>
      {/* <label class="lbl">Payment Method: <span class="must">*</span></label>
            <div class="paymentOptions">
              <div class="paymentOption">
                  <input type="radio" id="pm_cc" name="payment_mode" class="payment_mode" value="1" checked={!echeck}   onChange={(e) => {
              setEcheck(+e.target.value === 2);}} />
                  <label htmlFor="pm_cc">Debit/Credit Card</label>
              </div>
              <div class="paymentOption">
                  <input type="radio" id="pm_ec" name="payment_mode" class="payment_mode" value="2" onChange={(e) => {
              setEcheck(+e.target.value === 2);}} />
                  <label htmlFor="pm_ec">ECheck</label>
              </div>
           </div> */}

      <div className="radio-btn-group">
        <input
          type="radio"
          id="pm_cc"
          name="payment_mode"
          className="form-check-input"
          value="1"
          checked={!echeck}
          onChange={(e) => {
            setEcheck(+e.target.value === 2);
          }}
        />
        <label htmlFor="pm_cc">Debit/Credit Card</label>
{/* 
        <input
          type="radio"
          id="pm_ec"
          name="payment_mode"
          className="form-check-input"
          value="2"
          onChange={(e) => {
            setEcheck(+e.target.value === 2);
          }}
        />
        <label htmlFor="pm_ec">ACH Transfer</label> */}
      </div>
    </>
  );
};

export default EcheckRadioButtons;
