import API from "../utils/api";

const getImages = (pageNo) => {
    return API.get(`/gallery?page=${pageNo}`)
        .then((response) => {
            return response.data;
        });
};
const GalleryService = {
    getImages
};

export default GalleryService;