import React, {useEffect} from 'react';
import {PageHeader} from "../../components";
import {VisionGoal, WhatWeDo, WhoWeAre} from "./components/AboutComponent";

const breadcrumbItems = [
    {
        title: 'Home',
        route: '/',
        isLast: false
    },
    {
        title: 'About',
        route: '/',
        isLast: true
    }
]

const About = () => {

    useEffect(() => {
        document.title = "About | Ummah Relief"
    }, [])
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title="About"/>
            <WhoWeAre/>
            <VisionGoal />
            <WhatWeDo />
        </>
    );
};

export default About;