import React, { useEffect } from "react";
import { PageNotFountErrorImage } from "../../utils/Images";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Page Not Found | Ummah Relief";
    scrollTop();
  }, []);

  return (
    <div className="container-fluid my-5 text-center">
      <img src={PageNotFountErrorImage} alt="" style={{ width: "100%" }} />
      <Link to="/" className="btn btn-primary">
        Go Back to Home
      </Link>
    </div>
  );
};

export default PageNotFound;
