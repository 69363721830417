const validationErrors = (errors) => {
    let errorString = '<div className="px-3">'
    if (typeof errors === 'object') {
        Object.values(errors).map((value, index) => {
            errorString += (index + 1) + '. <span>' + value[0] + '</span><br>'
        })
    } else {
        errorString += `1. <span>${errors}</span>`
    }
    errorString += '</div>'
    return errorString
}

const ErrorFormat = {
    validationErrors,
};

export default ErrorFormat;