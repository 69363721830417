import styled from 'styled-components';

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: ${window.innerWidth<=400 ? '85%' : '95%'};
  bottom: 80px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: green;
`