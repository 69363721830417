import React, { useEffect } from "react";
import { PageHeader } from "../../components";
import "./components/CauseDetailComponent/customStyle.css";
import {
  ContactDetailComponent,
  ContactForm,
  ContactMap,
} from "./components/ContactComponent";

const breadcrumbItems = [
  {
    title: "Home",
    route: "/",
    isLast: false,
  },
  {
    title: "Contact",
    route: "/",
    isLast: true,
  },
];

const Contact = () => {
  
  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    document.title = "Contact | Ummah Relief";
    scrollTop();
  }, []);

  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title="Contact Us" />
      <ContactDetailComponent />
      <ContactForm />
      <ContactMap />
    </>
  );
};

export default Contact;
