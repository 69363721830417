import React, { useEffect, useState } from 'react';
import { PageHeader, PaginationComponent } from "../../components";
import { BlogList, SearchBlogList } from "./components/BlogsComponent";
import { Link, useLocation, useParams } from "react-router-dom";
import { LogoLoader } from "../../utils/Images";
import CauseCard from "./components/CausesComponent/CauseCard";
import { CauseList, SearchCauseList } from "./components/CausesComponent";
import CausesService from "../../services/causes.service";
import BlogService from "../../services/blogs.service";

const Search = () => {
    const params = useParams()
    const [activeTab, setActiveTab] = useState("blogs")
    const [causeData, setCauseData] = useState({})
    const [blogData, setBlogData] = useState({})
    const breadcrumbItems = [
        {
            title: 'Home',
            route: '/',
            isLast: false
        },
        {
            title: 'Search',
            // title: 'Search By ' + params.searchBy,
            route: '',
            isLast: true
        },
        {
            title: params.searchBy === 'category'
                ? 'Category'
                : params.searchBy === 'tag'
                    ? 'Tage: ' + params.id
                    : 'Keywords: ' + params.id,
            // title: params.searchBy === 'category'
            //     ? 'Category ID: ' + params.id
            //     : params.searchBy === 'tag'
            // ? 'Tage: ' + params.id
            // : 'Keywords: ' + params.id,
            route: '',
            isLast: true
        }
    ]

    const scrollTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const getCauses = () => {
        let searchType;
        if (params.searchBy === 'category') {
            searchType = 1
        } else if (params.searchBy === 'Tage') {
            searchType = 2
        } else {
            searchType = 3
        }
        CausesService.getCausesByQuery(searchType, { searchPhrase: params.id })
            .then(data => {
                setCauseData(data)
            })
    }

    const getBlogs = () => {
        let searchType;
        if (params.searchBy === 'category') {
            searchType = 1
        } else if (params.searchBy === 'Tage') {
            searchType = 2
        } else {
            searchType = 3
        }
        BlogService.getCBlogsByQuery(searchType, { searchPhrase: params.id })
            .then(data => {
                setBlogData(data)
            })
    }

    useEffect(() => {
        // document.title = `Search By ${params.searchBy} | Ummah Relief`
        document.title = `Search for ${params.id} | Ummah Relief`
        getCauses()
        getBlogs()
        scrollTop()
    }, [])

    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title={`Search By ${params.searchBy}`} />
            <section
                className="our-overview-area pos-rel  wow fadeInUp2  animated my-5"
                data-wow-delay=".1s"
                style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInUp2"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">

                            <ul className="nav nav-tabs nav-tabs-02 gap-3" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <Link
                                        to=""
                                        onClick={() => setActiveTab('blogs')}
                                        className={`nav-link theme_btn ${activeTab === 'blogs' ? 'active' : ''}`}
                                        id="blogs-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="blogs"
                                        aria-selected={activeTab === 'blogs'}
                                    >
                                        Blogs
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to=""
                                        onClick={() => setActiveTab('causes')}
                                        className={`nav-link theme_btn ${activeTab === 'causes' ? 'active' : ''}`}
                                        id="causes-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="causes"
                                        aria-selected={activeTab === 'causes'}
                                    >
                                        Causes
                                    </Link>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">

                                <div
                                    className={`tab-pane fade ${activeTab === 'blogs' ? 'show active' : ''}`}
                                    id="blogs"
                                    role="tabpanel"
                                    aria-labelledby="blogs-tab"
                                >
                                    <SearchBlogList blogData={blogData} />
                                </div>
                                <div
                                    className={`tab-pane fade ${activeTab === 'causes' ? 'show active' : ''}`}
                                    id="causes"
                                    role="tabpanel"
                                    aria-labelledby="causes-tab"
                                >
                                    <SearchCauseList causeData={causeData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<BlogList />*/}
        </>
    );
};

export default Search;