import React, { useEffect, useState } from 'react';

import ContactService from '../../../../services/contact.service';
import ErrorFormat from '../../../../utils/ErrorFormatting';
import FormValidator from '../../../../utils/validation';
import { MaskedInput } from '../../../../components';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const [type, setType] = useState('Inquiry');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [responseMessage, setResponseMessage] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    setErrorMessage('');

    if (
      errors &&
      (errors.name !== '' ||
        errors.email !== '' ||
        errors.phone ||
        errors.text_message)
    ) {
      setDisableSubmit(false);
      return setErrorMessage('Please solve the error to send message!');
    } else {
      const data = {
        type: type,
        subject: subject,
        message: message,
        name: name,
        email: email,
        phone_number: phone,
      };
      ContactService.submitQuery(data)
        .then((data) => {
          setDisableSubmit(false);
          if (data.status) {
            Swal.fire('Thanks for helping!', `${data.message}`, 'success').then(
              (r) => window.location.reload()
            );
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(data.message),
            });
          }
        })
        .catch((err) => {
          setDisableSubmit(false);
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: err.response.data.message,
            });
          }
        });
    }
  };

  const reset = () => {
    setName('');
    setSubject('');
    setMessage('');
    setEmail('');
    setPhone('');
    setDisableSubmit(true);
    setErrors({});
  };

  return (
    <section
      className="contact-details-area pb-120 wow fadeInUp2  animated"
      data-wow-delay=".3s"
      style={{
        visibility: 'visible',
        animationDelay: '0.3s',
        animationName: 'fadeInUp2',
      }}
    >
      <div className="container">
        <form onSubmit={(e) => handleSubmit(e)} className="row">
          <div className="col-xl-12">
            <div className="post-form-area contact-form pt-125">
              {Object.keys(responseMessage).length > 0 ? (
                responseMessage.success ? (
                  <div className="alert alert-success" role="alert">
                    {responseMessage.message}
                  </div>
                ) : (
                  <div className="alert alert-danger" role="alert">
                    {responseMessage.message}
                  </div>
                )
              ) : (
                <></>
              )}

              <div className="row">
                {errorMessage && errorMessage !== '' && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="input-box">
                    <h5>
                      Your Full Name <span className="text-danger">*</span>
                    </h5>
                    <div className="input-text mb-35">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                        onChange={(e) =>
                          setName(e.target.value.replace(/^\d+$/, ''))
                        }
                        className="form-control"
                        placeholder="Full Name Here"
                        required={true}
                      />
                      {errors.name !== '' && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="input-box mb-35">
                    <h5>
                      Your Email Address <span className="text-danger">*</span>
                    </h5>
                    <div className="input-text input-mail">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Email Here"
                        required
                      />
                      {errors.email !== '' && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="input-box mb-35">
                    <h5>
                      Phone Number <span className="text-danger">*</span>
                    </h5>
                    <div className="input-text input-phone">
                      <MaskedInput
                        mask="+99999999999"
                        type="tel"
                        id="phone"
                        name="phone"
                        onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        placeholder="Write Your Phone Number"
                        required
                      />
                      {errors.phone !== '' && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="input-box mb-35">
                    <h5>
                      Subject <span className="text-danger">*</span>
                    </h5>
                    <div className="input-text input-sub">
                      <input
                        type="text"
                        value={subject}
                        id="subject"
                        name="subject"
                        onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                        onChange={(e) =>
                          setSubject(e.target.value.replace(/^\d+$/, ''))
                        }
                        className="form-control"
                        placeholder="I Would Like To"
                        required
                      />
                      {errors.subject !== '' && (
                        <small className="text-danger">{errors.subject}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="input-box mb-35">
                    <h5>
                      Leave A Message <span className="text-danger">*</span>
                    </h5>
                    <div className="input-text input-message">
                      <textarea
                        name="text_message"
                        id="text_message"
                        cols={30}
                        rows={10}
                        placeholder="Write Your Message"
                        defaultValue={''}
                        onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                      {errors.text_message !== '' && (
                        <small className="text-danger">
                          {errors.text_message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-6">
                  <div className="msg-btn text-md-center d-flex justify-content-center align-items-center">
                    <button
                      // className="text-uppercase btn btn-outline-light theme_btn_background text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg "
                      // style={{
                      //   borderRadius: "20px",
                      //   borderWidth: "2px",
                      //   fontSize: "12px",
                      // }}
                      className={`theme_btn theme_btn_bg border-0 mx-auto`}
                      type="submit"
                    >
                      send message {/* <i className='fas fa-arrow-right' /> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
