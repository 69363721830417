export const themeConfig = {
    changeDarkMode: () => {
        const root = document.getElementsByTagName('html')[0];
        if (localStorage.getItem("dark")==='true') {
            root.classList.add('theme-dark')
        } else {
            root.classList.remove('theme-dark')
        }
    },
    green: '#259447',
    dark: '#262F36',
    lightDark: '#2F3742',
    orange: '#EF5D19'
}