import "./customStyle.css";

import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import FormValidator from "../../../../utils/validation";
import { MaskedInput } from "../../../../components";

const CauseTopCard = ({ cause }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [progressBar, setProgressBar] = useState(0);

  useEffect(() => {
    let percentage = cause.raised / cause.goal;
    if (percentage) {
      setProgressBar(percentage * 100);
    } else {
      setProgressBar(0);
    }
  }, [cause]);

  const handleInput = (clickedType) => {
    if (clickedType === "increment") {
      // console.log(typeof amount);
      // console.log(amount.length)
      // const oldAmount = amount
      if (amount.length > 6) {
        setAmount(amount);
      } else {
        let newAmount = Number(amount) + Number("1");
        // console.log(oldAmount)
        setAmount(newAmount);
      }
    } else {
      if (amount > 0) {
        setAmount(amount - 1);
      }
    }
  };

  useEffect(() => {
    if (amount < 0) {
      setAmount(0);
    }
  }, [amount]);

  return (
    <section
      className="project-image-area grey-bg pt-130 pb-100"
      style={{
        backgroundImage:
          "url(https://www.devsnews.com/template/fande/fande/assets/img/shape/09.png)",
      }}
    >
      <div className="container bg-white">
        <div className="d-flex flex-column  flex-lg-row align-items-center justify-content-center">
          <div className="col-xl-7">
            <div className="pos-rel py-3">
              <div
                className="project-img wow fadeInUp2  animated"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp2",
                }}
              >
                <img
                  // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${cause.image}`}
                  src={`${cause.image}`}
                  className=""
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="col-xl-5  wow fadeInUp2  animated"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp2",
            }}
          >
            <div className="projects project-cart white-bg mb-30 custom-card">
              <div className="projects__content px-3 py-3">
                <NavLink
                  to={`/search/category/${cause.categories_id}`}
                  className="new-tag text-decoration-none"
                >
                  {cause.category}
                </NavLink>
                <h3>{cause.title}</h3>

                <div className="skill mb-20">
                  {/* <p className='skill-para tri-card-p'>
                    {cause.goal ? (
                      <>
                        Goal <span>${cause.goal ? cause.goal : 0}</span>
                      </>
                    ) : (
                      <></>
                    )}
                    {'  '}Raised <span>${cause.raised ? cause.raised : 0}</span>
                  </p> */}
                  {/* {cause.goal ? (
                    <div
                      className={` ${
                        progressBar !== 0
                          ? `progress  mb-3`
                          : 'display-hidden mb-2'
                      }`}
                    >
                      <div
                        className='progress-bar'
                        role='progressbar'
                        style={{ width: `${Math.ceil(progressBar)}%` }}
                        aria-valuenow={Math.ceil(progressBar)}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span
                          className='wow cssanimation fadeInLeft'
                          style={{
                            visibility: 'visible',
                            animationName: 'bfadeInLeft',
                          }}
                        >
                          {Math.ceil(progressBar) > 100
                            ? 100
                            : Math.ceil(progressBar)}
                          %
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
                <p className="tri-card-p">{cause.short_desc}</p>
                <ul className="cart-list d-sm-flex flex-column flex-sm-row flex-lg-column  flex-xl-row flex-xxl-row align-items-center p-0 gap-3">
                  <li className="w-100">
                    <form
                      className="cart-plus-minus w-100"
                      action="https://www.devsnews.com/template/fande/fande/form.php"
                    >
                      <div
                        className="plus-minus pos-rel w-100"
                        style={{ zIndex: 999 }}
                      >
                        <input
                          value={amount}
                          placeholder="0"
                          onInput={(e) =>
                            FormValidator.numberMaxCheck(0, 6, e.target.value)
                          }
                          className="w-100"
                          onChange={(e) =>
                            setAmount(e.target.value.replace(/\D/g, ""))
                          }
                          minLength={0}
                          maxLength={6}
                        />
                        {}
                        <div
                          className="updown plus"
                          style={{ cursor: "pointer", top: 11 }}
                          onClick={() => handleInput("decrement")}
                        >
                          <i className="fas fa-chevron-left" />
                          <span className="fw-bold" style={{ fontSize: 20 }}>
                            $
                          </span>
                        </div>
                        <div
                          className="updown minus"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleInput("increment")}
                        >
                          <i className="fas fa-chevron-right" />
                        </div>
                      </div>
                    </form>
                  </li>
                  <li className="w-100">
                    <button
                      className="theme_btn theme_btn_bg border-0 d-flex w-100"
                      // className="text-uppercase btn btn-light text-success fw-bold px-4 tri-card-p border "
                      // style={{
                      //   borderRadius: "20px",
                      //   borderWidth: "2px",
                      //   fontSize: "12px",
                      // }}
                      onClick={() =>
                        navigate(`/causes/donation/${cause.id}`, {
                          replace: true,
                          state: {
                            amount: amount,
                            cause: cause,
                          },
                        })
                      }
                      data-animation="fadeInLeft"
                      data-delay=".5s"
                    >
                      Donate now
                      <i className="fas fa-arrow-right" />
                    </button>
                  </li>
                </ul>
                {/*<p className="text-danger">Amount is in dollars</p>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CauseTopCard;
